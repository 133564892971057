import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  loading: { name: null, status: null, message: null },
};

const generalSlice = createSlice({
  name: 'general',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetLoading: (state, action) => {
      state.loading = { name: null, status: null, message: null };
    },
  },
});

// Actions
export const { setLoading, resetLoading } = generalSlice.actions;

// Slice
export { generalSlice };
