import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Popover,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';

import { CopyIcon, QrcodeIcon, InfoIcon, EditIcon } from '../../reusable/icons';
import BasicDialog from '../../reusable/BasicDialog';
import PetraButton from '../../reusable/PetraButton';
import PetraTextField from '../../reusable/PetraTextField';
import PetraInputLabel from '../../reusable/PetraLabel';
import PetraInputUpDown from '../../reusable/PetraInputUpDown';
import { setLoading } from '../../../redux/slices/generalSlice';
import { LOADING_STATUS } from '../../../utils/constants';
import {
  removeAccount,
  updateAccount,
} from '../../../redux/slices/accountsSlice';
import DeleteAccountButton from './DeleteAccountButton';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.action2.main,
  },
  dialogAddress: {
    padding: '15px 12px',
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  dialogAddressText: {
    fontSize: 14,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dialogButtonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& :last-child': {
      marginLeft: 25,
    },
  },
  popover: {
    maxWidth: 300,
    padding: 16,
    backgroundColor: 'rgba(124, 158, 175, 1)',
    borderRadius: 20,

    '& p': {
      fontSize: 12,
    },
  },
  qrAddressMobile: { fontSize: '0.65rem' },
  qrAddress: { marginBottom: 24 },
}));

const EditAccountButton = ({
  account,
  updateAccount,
  removeAccount,
  setLoading,
  length,
  buttonClassName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const [showSetting, setShowSetting] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [anchorPopover, setAnchorPopover] = useState(null);
  const [newSetting, setNewSetting] = useState({
    name: account.name,
    order: account.order,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setNewSetting({ name: account.name, order: account.order });
  }, [account]);

  const handleChange = (field) => (event) => {
    setNewSetting({
      ...newSetting,
      [field]: event.target ? event.target.value : event.current.value,
    });
  };

  const handleSaveNewSetting = () => {
    if (newSetting.name && newSetting.order && isValidOrder(newSetting.order)) {
      updateAccount({
        address: account.address,
        name: newSetting.name,
        order: newSetting.order,
      });
      setShowSetting(false);
      setLoading({
        name: 'account/update',
        status: LOADING_STATUS.SUCCESS,
        message: t('accounts.settings_saved'),
      });
    }
  };

  const isValidOrder = (order) => {
    var result = parseInt(order) <= length && parseInt(order) > 0;
    return result;
  };

  const copyToClipboard = (value) => (event) => {
    setAnchorPopover(event.currentTarget);
    navigator.clipboard.writeText(value);
  };

  const handleEdit = () => {
    setShowSetting(true);
    setNewSetting({
      name: account.name,
      order: account.order,
    });
  };

  const handleRemove = () => {
    setShowSetting(false);
    setLoading({
      name: 'account/delete',
      status: LOADING_STATUS.SUCCESS,
      message: t('accounts.removed'),
    });
    if (location.pathname.includes('account')) {
      history.push('/account');
    } else {
      history.push('/');
    }
  };

  return (
    <>
      <Tooltip title={t('general.edit')}>
        <IconButton
          size='small'
          className={buttonClassName}
          onClick={handleEdit}
        >
          <EditIcon fontSize='inherit' />
        </IconButton>
      </Tooltip>

      <BasicDialog
        title={t('dialogs.account_settings.dialog_title')}
        open={showSetting}
        onClose={() => setShowSetting(false)}
      >
        <div>
          <PetraInputLabel>
            {t('dialogs.account_settings.address_label')}
          </PetraInputLabel>
          <div className={classes.dialogAddress}>
            <Typography className={classes.dialogAddressText}>
              {account.address}
            </Typography>
            <Tooltip title={t('general.copy_to_clipboard')}>
              <IconButton
                size='small'
                className={classes.icon}
                onClick={copyToClipboard(account.address)}
              >
                <CopyIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('general.qr_code')}>
              <IconButton
                size='small'
                className={classes.icon}
                onClick={() => setShowQrCode(true)}
              >
                <QrcodeIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </div>

          <PetraInputLabel htmlFor='account-name'>
            {t('dialogs.account_settings.name_label')}
          </PetraInputLabel>
          <PetraTextField
            id='account-name'
            style={{ marginBottom: 10 }}
            value={newSetting.name}
            error={!newSetting.name || newSetting.name === ''}
            helperText={
              !newSetting.name || newSetting.name === ''
                ? t('dialogs.edit_contact.required_error')
                : null
            }
            onChange={handleChange('name')}
          />

          <PetraInputLabel htmlFor='account-order'>
            {t('dialogs.account_settings.order_label')}
          </PetraInputLabel>
          <PetraInputUpDown
            id='account-order'
            value={newSetting.order}
            onChange={handleChange('order')}
            error={
              !newSetting.order ||
              newSetting.order === '' ||
              !isValidOrder(newSetting.order)
            }
            helperText={
              !newSetting.order || newSetting.order === ''
                ? t('dialogs.edit_contact.number_required')
                : !isValidOrder(newSetting.order)
                ? t('dialogs.edit_contact.number_invalid') + length
                : null
            }
            inputProps={{
              min: 1,
            }}
          />

          <Box mb={3} />
          <div className={classes.dialogButtonGroup}>
            <DeleteAccountButton
              account={account}
              isIcon={false}
              onRemove={handleRemove}
            />
            <PetraButton onClick={handleSaveNewSetting}>
              {t('dialogs.account_settings.save_button')}
            </PetraButton>
          </div>
        </div>
      </BasicDialog>

      <BasicDialog
        title={t('general.address')}
        open={showQrCode}
        onClose={() => setShowQrCode(false)}
      >
        <Box my={3} display='flex' flexDirection='column' alignItems='center'>
          <Typography
            className={`${classes.qrAddress} ${
              isMobile ? classes.qrAddressMobile : null
            }`}
          >
            {account.address}
          </Typography>
          <QRCode
            style={{ borderRadius: 20 }}
            includeMargin={true}
            size={isMobile ? 150 : 300}
            value={account.address}
          />
        </Box>
      </BasicDialog>

      <Popover
        // id={id}
        open={Boolean(anchorPopover)}
        anchorEl={anchorPopover}
        onClose={() => setAnchorPopover(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        // className={classes.popover}
        PaperProps={{
          classes: {
            root: classes.popover,
          },
          style: {},
        }}
      >
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {t('accounts.address_copied_tip')}
        </Typography>
      </Popover>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateAccount: (account) => dispatch(updateAccount(account)),
  removeAccount: (address) => dispatch(removeAccount(address)),
  setLoading: (data) => dispatch(setLoading(data)),
});

export default connect(null, mapDispatchToProps)(EditAccountButton);
