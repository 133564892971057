import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { AttentionIcon } from './icons';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles, withStyles, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrency } from '../../redux/selectors/settingsSelector';
import { getPrice } from '../../services/BlockchainService';

const useStyle = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.extension1.main,
    fontSize: 20,
    verticalAlign: 'baseline',
  },
  labelAmount: {
    color: theme.palette.extension1.main,
    fontSize: 24,
  },
  label: {
    color: theme.palette.extension1.main,
  },
  labelDollar: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 20,
    opacity: 0.7,
  },
  caption: {
    opacity: 0.5,
    marginLeft: 28,
  },
  tooltip: {
    fontSize: 25,
  },
}));

const BiggerTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 16,
  },
}))(Tooltip);

const PetraAmount = ({ showInfo = true, amount, rawPta, dollar, currency }) => {
  const classes = useStyle();
  const [petraPrice, setPetraPrice] = useState(0);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  useEffect(async () => {
    let price = await getPrice(currency.value);
    setPetraPrice(price);
  }, []);

  useEffect(() => {
    window.addEventListener('online', (event) => {
      setIsOffline(!navigator.onLine);
    });
  }, []);

  return (
    <>
      <Box mb={0} position='relative'>
        <Box>
          <BiggerTooltip title={amount} placement='bottom'>
            <Typography
              variant='h5'
              component='span'
              className={classes.labelAmount}
            >
              <Tooltip title='Petrachor Amount' placement='left'>
                <IconButton
                  size='small'
                  className={classes.infoIcon}
                ></IconButton>
              </Tooltip>
              {parseFloat(amount).toFixed(6)}
            </Typography>
          </BiggerTooltip>
          <Typography
            variant='body2'
            component='span'
            className={classes.label}
          >
            {' '}
            PTA
          </Typography>
        </Box>
        <Box  style={{display: 'flex', alignItems: 'center'}} >
          <BiggerTooltip
            title={currency?.value?.toUpperCase()}
            placement='bottom'
          >
            <Typography
              component='span'
              className={classes.labelDollar}
              variant='body1'
            >
              {currency?.symbol}
              {(parseFloat(amount) * petraPrice).toFixed(6)}
            </Typography>
          </BiggerTooltip>
          {
            isOffline && (
              <Tooltip title='Offline mode. Value is approximated' placement='bottom'>
                <AttentionIcon></AttentionIcon>
              </Tooltip>
            )
          }
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currency: selectCurrency,
});

export default connect(mapStateToProps)(PetraAmount);
