import React from 'react';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
  },
  badgeCount: {
    fontSize: 10,
    height: 15,
    marginLeft: 5,
  },
  btnNew: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addIcon: {
    padding: '3px 12px',
    background: theme.palette.action2.main,
    borderRadius: 34,
  },
}));

const PetraTitle = ({ title, badgeCount, handleAdd, addLabel }) => {
  const classes = useStyle();
  return (
    <div className={classes.titleContainer}>
      <Typography variant='h5'>
        {title}
        {badgeCount !== undefined && badgeCount !== null && (
          <Chip className={classes.badgeCount} size='small' label={badgeCount} />
        )}
      </Typography>
      {handleAdd && addLabel && (
        <Button
          className={classes.btnNew}
          disableRipple
          endIcon={<AddRoundedIcon className={classes.addIcon} />}
          onClick={handleAdd}
        >
          {addLabel}
        </Button>
      )}
    </div>
  );
};

export default PetraTitle;
