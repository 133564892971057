import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import {
  makeStyles,
  Tooltip,
  Popover,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';

import {
  CopyIcon,
  EditIcon,
  QrcodeIcon,
  AttentionIcon,
} from '../reusable/icons';
import PetraCard from '../reusable/PetraCard';
import BasicDialog from '../reusable/BasicDialog';
import PetraInputLabel from '../reusable/PetraLabel';
import PetraTextField from '../reusable/PetraTextField';
import PetraInputUpDown from '../reusable/PetraInputUpDown';
import PetraButton from '../reusable/PetraButton';
import { removeContact, updateContact } from '../../redux/slices/contactsSlice';
import { connect } from 'react-redux';

const useStyle = makeStyles((theme) => ({
  contactContainer: {
    marginBottom: 3,
    maxWidth: '100%',
  },
  contactName: {
    fontSize: '1rem',
  },
  keystoreHolder: {},
  keystore: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 10,
    marginBottom: 15,
  },
  caption: {
    opacity: 0.7,
    fontSize: 12,
  },
  icon: {
    color: theme.palette.action2.main,
    fill: theme.palette.action2.main,
  },
  // Dialog
  dialogAddress: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
  },
  dialogBlockchain: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 23,
  },
  dialogButtonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& :last-child': {
      marginLeft: 25,
    },
  },
  dialogWarning: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 50,
  },
  dialogWarningTitle: {
    fontSize: 12,
  },
  dialogWarningKeystore: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: 'calc(100% - 1px)',
  },
  dialogRemoveWarning: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#EB5757',
  },
  dialogWarningButtonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,

    '& :last-child': {
      marginLeft: 20,
    },
  },
  popover: {
    maxWidth: 300,
    padding: 16,
    backgroundColor: 'rgba(124, 158, 175, 1)',
    borderRadius: 20,

    '& p': {
      fontSize: 12,
    },
  },
  qrAddressMobile: { fontSize: '0.65rem' },
  qrAddress: { marginBottom: 24 },
}));

const Contact = ({
  contact,
  type = 1,
  onSelect,
  updateContact,
  removeContact,
  length,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [updatedContact, setUpdatedContact] = useState(contact);
  const [anchorPopover, setAnchorPopover] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleChange = (field) => (event) => {
    setUpdatedContact({
      ...updatedContact,
      [field]: event.target ? event.target.value : event.current.value,
    });
  };

  const handleSave = () => {
    if (
      updatedContact.name &&
      updatedContact.order &&
      isValidOrder(updatedContact.order)
    ) {
      updateContact(updatedContact);
      setOpenEditDialog(false);
    }
  };

  const handleRemove = () => {
    removeContact(contact.address);
    setOpenRemoveDialog(false);
    setOpenEditDialog(false);
  };

  const copyToClipboard = (value) => (event) => {
    setAnchorPopover(event.currentTarget);
    navigator.clipboard.writeText(value);
  };

  const isValidOrder = (order) => {
    var result = parseInt(order) <= length && parseInt(order) > 0;
    return result;
  };

  return (
    <PetraCard>
      <Grid container className={classes.contactContainer}>
        <Grid item xs={12} container alignItems='center'>
          <Grid item style={{ flexGrow: 1 }}>
            <Box
              style={{ cursor: 'pointer' }}
              onClick={onSelect ? onSelect(contact.address) : () => {}}
            >
              <Typography variant='subtitle1' className={classes.contactName}>
                {contact.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            {type === 2 && (
              <>
                <IconButton
                  size='small'
                  className={classes.icon}
                  onClick={copyToClipboard(contact.address)}
                >
                  <CopyIcon fontSize='inherit' />
                </IconButton>
              </>
            )}

            {type === 1 && (
              <>
                <Tooltip title={t('general.qr_code')}>
                  <IconButton
                    size='small'
                    className={classes.icon}
                    onClick={() => setOpenQrDialog(true)}
                  >
                    <QrcodeIcon fontSize='inherit' width={24} height={24} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('general.copy_to_clipboard')}>
                  <IconButton
                    size='small'
                    className={classes.icon}
                    onClick={copyToClipboard(contact.address)}
                  >
                    <CopyIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('general.edit')}>
                  <IconButton
                    size='small'
                    className={classes.icon}
                    onClick={() => {
                      setOpenEditDialog(true);
                      setUpdatedContact(contact);
                    }}
                  >
                    <EditIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={9} className={classes.keystoreHolder}>
          <Typography className={classes.keystore}>
            {contact.address}
          </Typography>
          <Typography variant='caption' className={classes.caption}>
            {contact.blockchain}
          </Typography>
        </Grid>
      </Grid>

      <BasicDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        title={t('dialogs.edit_contact.title')}
      >
        <PetraInputLabel>
          {t('dialogs.edit_contact.address_label')}
        </PetraInputLabel>
        <Box display='flex' m={1.5}>
          <Typography className={classes.dialogAddress}>
            {contact.address}
          </Typography>

          <Tooltip title={t('general.copy_to_clipboard')}>
            <IconButton
              size='small'
              className={classes.icon}
              onClick={copyToClipboard(contact.address)}
            >
              <CopyIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('general.qr_code')}>
            <IconButton
              size='small'
              className={classes.icon}
              onClick={() => setOpenQrDialog(true)}
            >
              <QrcodeIcon fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Box>

        <PetraInputLabel htmlFor='contact-name'>
          {t('dialogs.edit_contact.name_label')}
        </PetraInputLabel>
        <PetraTextField
          id='contact-name'
          value={updatedContact.name}
          onChange={handleChange('name')}
          error={!updatedContact.name || updatedContact.name === ''}
          helperText={
            !updatedContact.name || updatedContact.name === ''
              ? t('dialogs.edit_contact.required_error')
              : null
          }
        />
        <Box>
          <Typography className={classes.dialogBlockchain}>
            {updatedContact.blockchain}
          </Typography>
        </Box>

        <PetraInputLabel>
          {t('dialogs.edit_contact.order_label')}
        </PetraInputLabel>
        <PetraInputUpDown
          value={updatedContact.order}
          onChange={handleChange('order')}
          error={
            !updatedContact.order ||
            updatedContact.order === '' ||
            !isValidOrder(updatedContact.order)
          }
          helperText={
            !updatedContact.order || updatedContact.order === ''
              ? t('dialogs.edit_contact.number_required')
              : !isValidOrder(updatedContact.order)
              ? t('dialogs.edit_contact.number_invalid') + length
              : null
          }
          inputProps={{
            min: 1,
          }}
        />

        <div className={classes.dialogButtonGroup}>
          <PetraButton
            variant='outlined'
            onClick={() => setOpenRemoveDialog(true)}
          >
            {t('dialogs.edit_contact.remove_button')}
          </PetraButton>
          <PetraButton onClick={handleSave}>
            {t('dialogs.edit_contact.save_button')}
          </PetraButton>
        </div>
      </BasicDialog>

      <BasicDialog
        open={openQrDialog}
        onClose={() => setOpenQrDialog(false)}
        title={t('general.address')}
      >
        <Box my={3} display='flex' flexDirection='column' alignItems='center'>
          <Typography
            className={`${classes.qrAddress} ${
              isMobile ? classes.qrAddressMobile : null
            }`}
          >
            {contact.address}
          </Typography>
          <QRCode
            style={{ borderRadius: 20 }}
            includeMargin={true}
            size={isMobile ? 150 : 300}
            value={contact.address}
          />
        </Box>
      </BasicDialog>

      <BasicDialog
        maxWidth='xs'
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
        title={t('dialogs.remove_contact.title')}
      >
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          mt={2}
          mb={3}
        >
          <Typography className={classes.dialogWarningTitle}>
            {t('dialogs.remove_contact.sure_label')}
          </Typography>
          <Typography className={classes.dialogWarningKeystore}>
            {contact.keystore}
          </Typography>

          <div className={classes.dialogWarning}>
            <AttentionIcon
              width={15}
              height={15}
              style={{ marginRight: 9, color: '#EB5757' }}
            />
            <Typography className={classes.dialogRemoveWarning}>
              {t('dialogs.remove_contact.warning_label')}
            </Typography>
          </div>

          <div className={classes.dialogWarningButtonGroup}>
            <PetraButton
              variant='outlined'
              onClick={() => setOpenRemoveDialog(false)}
            >
              {t('dialogs.remove_contact.cancel_button')}
            </PetraButton>
            <PetraButton onClick={handleRemove}>
              {t('dialogs.remove_contact.remove_button')}
            </PetraButton>
          </div>
        </Box>
      </BasicDialog>
      <Popover
        // id={id}
        open={Boolean(anchorPopover)}
        anchorEl={anchorPopover}
        onClose={() => setAnchorPopover(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        // className={classes.popover}
        PaperProps={{
          classes: {
            root: classes.popover,
          },
          style: {},
        }}
      >
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {t('accounts.address_copied_tip')}
        </Typography>
      </Popover>
    </PetraCard>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateContact: (data) => dispatch(updateContact(data)),
  removeContact: (data) => dispatch(removeContact(data)),
});

export default connect(null, mapDispatchToProps)(Contact);
