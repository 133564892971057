import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { DeleteIcon, InfoIcon } from '../../reusable/icons';
import BasicDialog from '../../reusable/BasicDialog';
import PetraButton from '../../reusable/PetraButton';
import { setLoading } from '../../../redux/slices/generalSlice';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { LOADING_STATUS } from '../../../utils/constants';
import { removeAllAccounts } from '../../../redux/slices/accountsSlice';
import { selectAccountList } from '../../../redux/selectors/accountsSelector';
import { useHistory } from 'react-router';

const useStyle = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  dialogWarning: {
    display: 'flex',
  },
  dialogAddressText: {
    fontSize: 14,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  dialogRemoveWarning: {
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.action2.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  dialogWarning: {
    display: 'flex',
  },
  dialogWarningButtonGroup: {
    display: 'flex',
    justifyContent: 'center',

    '& :last-child': {
      marginLeft: 20,
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: 'auto',
  },
}));

const RemoveAccounts = ({ setLoading, accounts, removeAllAccounts }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  const handleConfirmRemove = () => {
    removeAllAccounts(accounts);
    setLoading({
      name: 'account/delete',
      status: LOADING_STATUS.SUCCESS,
      message: t('accounts.removed_all'),
    });
    setShowRemoveConfirm(false);
  };

  return (
    <>
      {accounts.length !== 0 ? (
        <Box
          onClick={() =>
            accounts.length > 0
              ? setShowRemoveConfirm(true)
              : setShowRemoveConfirm(false)
          }
          className={classes.icon}
        >
          <DeleteIcon />
          <Box mt={2}>
            <Typography variant='caption'>
              {t('home.remove_accounts')}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <BasicDialog
        title={t('dialogs.account_remove.dialog_title')}
        open={showRemoveConfirm}
        onClose={() => setShowRemoveConfirm(false)}
      >
        <div>
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <Typography className={classes.dialogAddressText}>
              {t('dialogs.account_remove.sure_label_all')}
            </Typography>
          </div>

          <div className={classes.dialogWarning}>
            <InfoIcon
              width={30}
              height={30}
              style={{ marginRight: 9, marginBottom: 40 }}
            />
            <Typography className={classes.dialogRemoveWarning}>
              {t('dialogs.account_remove.warning')}
            </Typography>
          </div>
          <div className={classes.dialogWarningButtonGroup}>
            <PetraButton
              variant='outlined'
              onClick={() => setShowRemoveConfirm(false)}
            >
              {t('dialogs.account_remove.cancel_button')}
            </PetraButton>
            <PetraButton onClick={handleConfirmRemove}>
              {t('dialogs.account_remove.confirm_button')}
            </PetraButton>
          </div>
        </div>
      </BasicDialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (data) => dispatch(setLoading(data)),
  removeAllAccounts: (data) => dispatch(removeAllAccounts(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveAccounts);
