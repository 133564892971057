import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as InfoIcon } from './icons/img/info-icon.svg';
import { ReactComponent as ErrorIcon } from './icons/img/error-icon.svg';

const useStyle = makeStyles((theme) => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 9,
    fill: theme.palette.action2.main,
  },
  text: {
    fontSize: 11,
    color: theme.palette.action2.main,
  },
}));

const InfoAlert = (props) => {
  const classes = useStyle();
  const theme = useTheme();

  const icons = (type) => {
    switch (type) {
      case 'info':
        return <InfoIcon className={classes.icon} />;
        break;
      case 'error':
        return <ErrorIcon className={classes.icon} />;
        break;
    }
  };

  return (
    <div className={classes.alert} {...props}>
      {icons(props.type)}
      <Typography
        className={classes.text}
        style={{
          color:
            props.type == 'error'
              ? theme.palette.action2.error
              : theme.palette.action2.main,
        }}
      >
        {props.text}
      </Typography>
    </div>
  );
};

export default InfoAlert;
