import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  selectAccountByAddress,
  selectAccountList,
} from '../../redux/selectors/accountsSelector';
import { selectCurrency } from '../../redux/selectors/settingsSelector';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  Tooltip,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BasicDialog from '../reusable/BasicDialog';
import { useHistory } from 'react-router-dom';
import {
  QrcodeIcon,
  CloudIcon,
  WalletIcon,
  SendIcon,
  ReceiveIcon,
} from '../reusable/icons/';
import DeleteAccountButton from './buttons/DeleteAccountButton';
import EditAccountButton from './buttons/EditAccountButton';
import CopyToClipboardButton from './buttons/CopyToClipboardButton';
import AccountTitle from './AccountTitle';
import PetraCard from '../reusable/PetraCard';
import AccountActionButton from './AccountActionButton';
import PetraAmount from '../reusable/PetraAmount';
import AccountSection from './Sections/AccountSection';
import SendSection from './Sections/SendSection';
import {
  getTransactions,
  downloadKeystore,
  getPrice,
  convertWeiToPetra,
  getBlockNumber,
  getAccountBalance,
} from '../../services/BlockchainService';
import ReceiveSection from './Sections/ReceiveSection';
import {
  updateAccountTransactions,
  removeAccount,
  updateAccountBalance,
} from '../../redux/slices/accountsSlice';
import { setLoading } from '../../redux/slices/generalSlice';
import { LOADING_STATUS } from '../../utils/constants';
import QRCode from 'qrcode.react';

const useStyle = makeStyles((theme) => ({
  accountDetailContainer: {
    padding: theme.spacing(3),
  },
  iconsHolder: {
    '& > *': {
      marginLeft: 6,
      marginRight: 6,
    },
  },
  icon: {
    fill: 'rgba(184, 107, 12)',
  },
  buttonRoot: {
    padding: 10,
    borderRadius: 18,
    borderWidth: 'unset',
  },
  accountActionButtonsHolder: {
    display: 'flex',
    flexDirection: 'row',
  },
  accountAmount: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 100,

    '& > p': {
      marginLeft: 5,
      opacity: 0.7,
      marginTop: 1,
      fontSize: 14,
    },
  },
  dialogAddressText: {
    fontSize: 14,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  popover: {
    maxWidth: 300,
    padding: 16,
    backgroundColor: 'rgba(124, 158, 175, 1)',
    borderRadius: 20,

    '& p': {
      fontSize: 12,
    },
  },
  qrAddressMobile: { fontSize: '0.65rem' },
  qrAddress: { marginBottom: 24 },
}));

const ACTION = Object.freeze({
  ACCOUNT: 0,
  SEND: 1,
  RECEIVE: 2,
});

const AccountDetail = ({
  selectedAccount,
  accounts,
  updateAccountTransactions,
  address,
  currency,
  updateAccountBalance,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedAction, setSelectedAction] = useState(ACTION.ACCOUNT);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [petraPrice, setPetraPrice] = useState(0);
  let history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(async () => {
    let transactions = await getTransactions(selectedAccount);
    let blockNumber = await getBlockNumber();
    updateAccountTransactions({
      address: selectedAccount.address,
      transactions,
      blockNumber,
    });
  }, []);

  const handleOnSend = () => {
    setSelectedAction(ACTION.ACCOUNT);
  };

  const handleDownloadKeystore = () => {
    downloadKeystore(selectedAccount.keystore);
  };

  const handleRemove = () => {
    history.push('/account');
  };

  useEffect(async () => {
    let balance = await getAccountBalance(selectedAccount);
    updateAccountBalance({
      address: selectedAccount.address,
      balance: balance,
    });
  }, accounts);
  useEffect(async () => {
    let price = await getPrice(currency.value);
    setPetraPrice(price);
  }, []);
  return (
    <div className={classes.accountDetailContainer}>
      {selectedAccount && (
        <div>
          <AccountTitle
            title={selectedAccount?.name}
            components={
              <div className={classes.iconsHolder}>
                <CopyToClipboardButton
                  address={selectedAccount.address}
                  buttonClassName={classes.icon}
                />
                <Tooltip title={t('general.qr_code')}>
                  <IconButton
                    size='small'
                    className={classes.icon}
                    onClick={() => setShowQrCode(true)}
                  >
                    <QrcodeIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('accounts.download_keystore')}>
                  <IconButton
                    size='small'
                    className={classes.icon}
                    onClick={handleDownloadKeystore}
                  >
                    <CloudIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>

                <EditAccountButton
                  account={selectedAccount}
                  length={accounts.length}
                  buttonClassName={classes.icon}
                />
                <DeleteAccountButton
                  account={selectedAccount}
                  isIcon={true}
                  onRemove={handleRemove}
                />
              </div>
            }
          />

          {/* Account Information Card */}
          <Box mb={3}>
            <PetraCard>
              <Grid container justify='space-between'>
                <Grid item className={classes.accountActionButtonsHolder}>
                  <AccountActionButton
                    index={ACTION.ACCOUNT}
                    value={selectedAction}
                    onSelected={(action) => setSelectedAction(action)}
                    label={t('account_details.account_button')}
                    Icon={WalletIcon}
                  />

                  <AccountActionButton
                    index={ACTION.SEND}
                    value={selectedAction}
                    onSelected={(action) => setSelectedAction(action)}
                    label={t('account_details.send_button')}
                    Icon={SendIcon}
                  />

                  <AccountActionButton
                    index={ACTION.RECEIVE}
                    value={selectedAction}
                    onSelected={(action) => setSelectedAction(action)}
                    label={t('account_details.receive_button')}
                    Icon={ReceiveIcon}
                  />
                </Grid>

                <Grid item className={classes.accountAmount}>
                  <PetraAmount
                    amount={convertWeiToPetra(selectedAccount?.pta?.toString())}
                    dollar={
                      convertWeiToPetra(selectedAccount?.pta?.toString()) *
                      petraPrice
                    }
                    rawPta={selectedAccount?.pta}
                  />
                  <Typography>{t('account_details.total_label')}</Typography>
                </Grid>
              </Grid>
            </PetraCard>
          </Box>

          {/* Section 1 - Account */}
          {selectedAction === ACTION.ACCOUNT && (
            <AccountSection
              selectedAccount={selectedAccount}
              transactions={selectedAccount.transactions}
            />
          )}

          {/* Section 2 - Send */}
          {selectedAction === ACTION.SEND && (
            <SendSection
              account={selectedAccount}
              handleOnSend={handleOnSend}
            />
          )}

          {/* Section 3 - Receive */}
          {selectedAction === ACTION.RECEIVE && (
            <ReceiveSection selectedAccount={selectedAccount} />
          )}
        </div>
      )}

      <BasicDialog
        title={t('general.address')}
        open={showQrCode}
        onClose={() => setShowQrCode(false)}
      >
        <Box my={3} display='flex' flexDirection='column' alignItems='center'>
          <Typography
            className={`${classes.qrAddress} ${
              isMobile ? classes.qrAddressMobile : null
            }`}
          >
            {selectedAccount.address}
          </Typography>
          <QRCode
            style={{ borderRadius: 20 }}
            includeMargin={true}
            size={isMobile ? 150 : 300}
            value={selectedAccount.address}
          />
        </Box>
      </BasicDialog>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  selectedAccount: selectAccountByAddress(props.match.params.address)(state),
  accounts: selectAccountList(state),
  currency: selectCurrency(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountTransactions: (value) =>
    dispatch(updateAccountTransactions(value)),
  removeAccount: (address) => dispatch(removeAccount(address)),
  updateAccountBalance: (value) => dispatch(updateAccountBalance(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetail);
