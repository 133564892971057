import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({

}));

const CustomFormControlLabel = withStyles((theme) => ({
  label: {
    fontSize: 12,
  },
}))(FormControlLabel);

const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.action2.main,
    '&$checked': {
      color: theme.palette.action2.main,
    },
  },
  checked: {},
}))(Checkbox);

const PetraCheckbox = ({  id, checked, label, onChangeValue, ...otherProps }) => {
  const classes = useStyles();
  return (
    <div {...otherProps}>
      <CustomFormControlLabel
        control={<CustomCheckbox checked={checked} onChange={onChangeValue} name={id} />}
        label={label}
      />
    </div>
  );
};

export default PetraCheckbox;
