export const LOADING_STATUS = Object.freeze({
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
});

export const TX_FEE = 0.00042;
export const OFFLINE_STATUS = "-999.999";

export const menuOptions = [
  { title: 'Home', path: '/', routeTitle: 'home' },
  { title: 'Accounts', path: '/account', routeTitle: 'account' },
  { title: 'Contacts', path: '/contacts', routeTitle: 'contacts' },
  { title: 'Settings', path: '/settings', routeTitle: 'settings' },
];
