import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Tooltip } from '@material-ui/core';

import { CopyIcon } from '../reusable/icons';
import EditAccountButton from './buttons/EditAccountButton';
import CopyToClipboardButton from './buttons/CopyToClipboardButton';
import PetraCard from '../reusable/PetraCard';
import PetraAmount from '../reusable/PetraAmount';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  walletContent: {
    marginBottom: 3,
    maxWidth: '100%',
  },
  icon: {
    fill: theme.palette.action2.main,
  },
  caption: {
    opacity: 0.5,
  },
  titleContainer: {
    marginBottom: 12,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  addressHolder: {
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      paddingTop: 15,
      paddingBottom: 15,
      paddingRight: 0,
    },
  },
  address: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: 10,
    marginBottom: 4,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
      marginTop: 0,
    },
  },
  totalLabel: {
    opacity: 0.7,
    marginLeft: 5,
    marginTop: 5,
  },
}));

const Account = ({
  name,
  address,
  order,
  pta,
  rawPta,
  dollar,
  type = 1,
  onSelect,
  account,
  length,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    history.push(`/account/${address}`);
  };

  return (
    <PetraCard>
      <Grid container className={classes.walletContent}>
        <Grid
          item
          xs={12}
          container
          alignItems='center'
          className={classes.titleContainer}
        >
          <Grid item style={{ flexGrow: 1 }}>
            <Box
              style={{ cursor: 'pointer' }}
              onClick={onSelect ? onSelect(address) : handleClick}
            >
              <Typography variant='subtitle1'>{name}</Typography>
            </Box>
          </Grid>
          {type === 1 && (
            <Box display={{ xs: 'none', md: 'block' }}>
              <Grid item>
                <CopyToClipboardButton
                  address={address}
                  buttonClassName={classes.icon}
                />
                <EditAccountButton
                  account={account}
                  length={length}
                  buttonClassName={classes.icon}
                />
              </Grid>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={8} className={classes.addressHolder}>
          <Typography className={classes.address}>{address}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          direction='row'
          justify='space-between'
        >
          <Grid item>
            <PetraAmount
              rawPta={rawPta}
              showInfo={true}
              amount={pta}
              dollar={dollar ?? 0}
            />
            <Typography variant='caption' className={classes.totalLabel}>
              {t('accounts.total_label')}
            </Typography>
          </Grid>
          {type === 1 && (
            <Box display={{ xs: 'block', md: 'none' }}>
              <Grid item>
                <CopyToClipboardButton
                  address={address}
                  buttonClassName={classes.icon}
                />
                <EditAccountButton
                  account={account}
                  length={length}
                  buttonClassName={classes.icon}
                />
              </Grid>
            </Box>
          )}
          {type === 2 && (
            <Box>
              <Grid item>
                <CopyToClipboardButton
                  address={address}
                  buttonClassName={classes.icon}
                />
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </PetraCard>
  );
};

export default Account;
