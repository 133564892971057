import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { validate } from 'validate.js';

import BasicDialog from '../reusable/BasicDialog';
import PetraTextField from '../reusable/PetraTextField';
import PetraLabel from '../reusable/PetraLabel';
import PetraButton from '../reusable/PetraButton';
import { DirectionUpIcon, DirectionDownIcon } from '../reusable/icons';
import {
  selectContactByAddress,
  selectContactsList,
} from '../../redux/selectors/contactsSelector';
import { selectAccountList } from '../../redux/selectors/accountsSelector';
import PetraSelect from '../reusable/PetraSelect';
import PetraTitle from '../reusable/PetraTitle';
import PetraSearch from '../reusable/PetraSearch';
import ContactList from './ContactList';
import { addContact } from '../../redux/slices/contactsSlice';
import PetraInputUpDown from '../reusable/PetraInputUpDown';
import HelperText from '../reusable/HelperText';
import { isHexStrict } from '../../services/BlockchainService';

const useStyle = makeStyles((theme) => ({
  contactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingBottom: 0,
    height: '100vh',
    overflow: 'hidden',
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      paddingBottom: 70,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    marginBottom: 6,
  },
  contactCount: {
    fontSize: 10,
    height: 15,
    marginLeft: 5,
  },
  btnNewContact: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  numberInput: {
    '& input[type="number"]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
      },
  },
  disableHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addIcon: {
    padding: '3px 12px',
    background: theme.palette.action2.main,
    borderRadius: 34,
  },
  bottomFade: {
    zIndex: 99,
    position: 'fixed',
    bottom: '0%',
    height: 40,
    width: '100%',
    pointerEvents: 'none',
    backgroundImage: `linear-gradient(to bottom, transparent, ${theme.palette.background.defaultGradient})`,
  },
}));

const Contacts = ({ contacts, addContact, accounts }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [openNewContactDialog, setOpenNewContactDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const contactSchema = {
    address: {
      presence: {
        allowEmpty: false,
        message: t('dialogs.new_contact.required_error'),
      },
      length: function (
        value,
        attributes,
        attributeName,
        options,
        constraints
      ) {
        if (!isHexStrict(value) || (value+'').length !== 42) {
          return {
            is: -1,
            message: t('dialogs.new_contact.address_valid_error'),
          };
        } else {
          return null;
        }
      },
      exclusion: {
        within: contacts
          ?.map((a) => a.address)
          .concat(accounts?.map((a) => a.address)),
        message: t('dialogs.new_contact.unique_error'),
      },
    },
    name: {
      presence: {
        allowEmpty: false,
        message: t('dialogs.new_contact.required_error'),
      },
    },
    order: {
      presence: {
        allowEmpty: false,
        message: t('dialogs.new_contact.required_error'),
      },
      numericality: {
        lessThan: contacts.length + 2,
        greaterThan: 0,
        message:
          t('dialogs.new_contact.number_invalid') + (contacts.length + 1),
      },
    },
  };

  const [contactState, setContactState] = useState({
    isValid: false,
    values: {
      order: contacts ? contacts.length + 1 : 0,
    },
    errors: {},
    touched: {},
  });

  useEffect(() => {
    let errors = validate(contactState.values, contactSchema);
    setContactState((prevState) => ({
      ...prevState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [contactState.values]);

  const hasError = (field) =>
    !!(contactState.touched[field] && contactState.errors[field]);

  const handleChange = (event) => {
    event.persist();

    setContactState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...prevState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleOrder = (event) => {
    setContactState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        order: event.current?.value || event.target?.value,
      },
      touched: {
        ...prevState.touched,
        ['order']: true,
      },
    }));
  };

  const handleAdd = () => {
    if (contactState.isValid) {
      addContact(contactState.values);
      setOpenNewContactDialog(false);
    } else {
      const touched = {};
      Object.entries(contactState.errors).forEach(
        ([key, value]) => (touched[key] = true)
      );
      setContactState((prevState) => ({
        ...prevState,
        touched: touched,
      }));
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={classes.contactsContainer}>
      <PetraTitle
        title={t('contacts.title')}
        badgeCount={contacts ? contacts.length : 0}
        handleAdd={() => {
          setContactState({
            isValid: false,
            values: {
              order: contacts ? contacts.length + 1 : 0,
              name: 'Contact ' + (contacts.length + 1),
            },
            errors: {},
            touched: {},
          });
          setOpenNewContactDialog(true);
        }}
        addLabel={t('contacts.new_contact_label')}
      />

      <PetraSearch onChange={handleSearch} />

      <ContactList searchValue={searchValue} />

      <div className={classes.bottomFade}></div>

      <BasicDialog
        open={openNewContactDialog}
        onClose={() => setOpenNewContactDialog(false)}
        title={t('dialogs.new_contact.title')}
      >
        <PetraLabel>{t('dialogs.edit_contact.address_label')}</PetraLabel>
        <PetraTextField
          name='address'
          value={contactState.values.address || ''}
          onChange={handleChange}
          error={hasError('address')}
          helperText={
            hasError('address') ? (
              <HelperText message={contactState.errors.address[0]} />
            ) : null
          }
        />

        <PetraLabel>{t('dialogs.edit_contact.name_label')}</PetraLabel>
        <PetraTextField
          name='name'
          value={contactState.values.name || ''}
          onChange={handleChange}
          error={hasError('name')}
          helperText={
            hasError('name') ? (
              <HelperText message={contactState.errors.name[0]} />
            ) : null
          }
        />

        <PetraLabel>{t('dialogs.edit_contact.order_label')}</PetraLabel>
        <PetraInputUpDown
          className={classes.numberInput}
          type='number'
          name='order'
          value={contactState.values.order}
          onChange={handleOrder}
          error={hasError('order')}
          helperText={
            hasError('order') ? (
              <HelperText message={contactState.errors.order[0]} />
            ) : null
          }
          inputProps={{
            min: 1,
          }}
        />
        <Box mt={2} display='flex' justifyContent='center'>
          <PetraButton type='submit' onClick={handleAdd}>
            {t('dialogs.edit_contact.save_button')}
          </PetraButton>
        </Box>
      </BasicDialog>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  contacts: selectContactsList,
  accounts: selectAccountList,
});

const mapDispatchToProps = (dispatch) => ({
  addContact: (data) => dispatch(addContact(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
