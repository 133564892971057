import React, { useRef } from 'react';
import PetraTextField from './PetraTextField';
import { makeStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { DirectionDownIcon, DirectionUpIcon } from './icons/';

const useStyle = makeStyles((theme) => ({
  numberInput: {
    '& input[type="number"]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
    },
    '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
      },
  },
  icon: {
    stroke: theme.palette.action2.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

/**
 * Please be aware to capture changes with
 * `event.current.value` instead of `event.target.value`
 */
const PetraInputUpDown = ({
  value,
  name,
  onChange,
  error,
  helperText,
  inputProps = {},
}) => {
  const classes = useStyle();
  const inputRef = useRef();

  return (
    <PetraTextField
      className={classes.numberInput}
      type='number'
      name={name}
      inputRef={inputRef}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputProps={{
        inputProps: inputProps,
        endAdornment: (
          <InputAdornment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
              }}
            >
              <IconButton
                className={classes.icon}
                aria-label='toggle password visibility'
                edge='end'
                disableRipple
                size='small'
                onClick={() => {
                  inputRef.current.stepUp();
                  onChange(inputRef);
                }}
              >
                {<DirectionUpIcon />}
              </IconButton>
              <IconButton
                className={classes.icon}
                aria-label='toggle password visibility'
                edge='end'
                disableRipple
                size='small'
                onClick={() => {
                  inputRef.current.stepDown();
                  onChange(inputRef);
                }}
              >
                {<DirectionDownIcon />}
              </IconButton>
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PetraInputUpDown;
