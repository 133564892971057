import React, { useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';

import { InfoIcon, ExitIcon } from '../../reusable/icons';
import BasicDialog from '../../reusable/BasicDialog';
import PetraButton from '../../reusable/PetraButton';
import { setLoading } from '../../../redux/slices/generalSlice';
import { LOADING_STATUS } from '../../../utils/constants';
import { removeAccount } from '../../../redux/slices/accountsSlice';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    fill: theme.palette.action2.main,
  },
  icon: {
    fill: 'rgba(184, 107, 12)',
  },
  dialogAddress: {
    padding: '15px 12px',
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
  },
  dialogAddressText: {
    fontSize: 14,
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  dialogWarning: {
    display: 'flex',
  },
  dialogRemoveWarning: {
    fontWeight: 'bold',
    fontSize: 11,
    color: theme.palette.action2.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },
  dialogWarningButtonGroup: {
    display: 'flex',
    justifyContent: 'center',

    '& :last-child': {
      marginLeft: 20,
    },
  },
}));

const DeleteAccountButton = ({ account, isIcon, onRemove, removeAccount }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  const handleConfirmRemove = () => {
    removeAccount(account.address);
    onRemove();
  };

  return (
    <>
      {isIcon && (
        <Tooltip title={t('accounts.remove_wallet')}>
          <IconButton
            size='small'
            className={classes.icon}
            onClick={() => {
              setShowRemoveConfirm(true);
            }}
          >
            <ExitIcon fontSize='inherit' />
          </IconButton>
        </Tooltip>
      )}
      {!isIcon && (
        <PetraButton
          variant='outlined'
          onClick={() => setShowRemoveConfirm(true)}
        >
          {t('dialogs.account_settings.remove_button')}
        </PetraButton>
      )}

      <BasicDialog
        title={t('dialogs.account_remove.dialog_title')}
        open={showRemoveConfirm}
        onClose={() => setShowRemoveConfirm(false)}
      >
        <div>
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <Typography className={classes.dialogAddressText}>
              {t('dialogs.account_remove.sure_label')}
            </Typography>
            <Typography className={classes.dialogAddressText}>
              {account.address}
            </Typography>
          </div>

          <div className={classes.dialogWarning}>
            <InfoIcon
              className={classes.infoIcon}
              width={30}
              height={30}
              style={{ marginRight: 9, marginBottom: 40 }}
            />
            <Typography className={classes.dialogRemoveWarning}>
              {t('dialogs.account_remove.warning')}
            </Typography>
          </div>
          <div className={classes.dialogWarningButtonGroup}>
            <PetraButton
              variant='outlined'
              onClick={() => setShowRemoveConfirm(false)}
            >
              {t('dialogs.account_remove.cancel_button')}
            </PetraButton>
            <PetraButton onClick={handleConfirmRemove}>
              {t('dialogs.account_remove.confirm_button')}
            </PetraButton>
          </div>
        </div>
      </BasicDialog>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeAccount: (address) => dispatch(removeAccount(address)),
  setLoading: (data) => dispatch(setLoading(data)),
});

export default connect(null, mapDispatchToProps)(DeleteAccountButton);
