import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: initialState,
  reducers: {
    addContact: (state, action) => {
      let hasMoreContacts = true;
      let startIndex = parseInt(action.payload.order) - 1;
      while(hasMoreContacts){
        if(state.list[startIndex]){
          state.list[startIndex].order = parseInt(state.list[startIndex].order) + 1;
          startIndex +=1 ;
        }else{
          hasMoreContacts = false;
        }
      }
      state.list.push(action.payload);
      state.list.sort((a, b) => (a.order > b.order) ? 1 : -1)
    },
    removeContact: (state, action) => {
      const index = state.list.findIndex((item) => item.address === action.payload);
      let hasMoreContacts = true;
      let startIndex = index;
      if (index >= 0) {
        while(hasMoreContacts){
          if(state.list[startIndex]){
            state.list[startIndex].order = parseInt(state.list[startIndex].order) - 1;
            startIndex +=1 ;
          }else{
            hasMoreContacts = false;
          }
        }
        state.list = state.list.filter((item) => item.address !== action.payload);
        state.list.sort((a, b) => (a.order > b.order) ? 1 : -1)
      }
    },
    updateContact: (state, action) => {
      const index = state.list.findIndex((item) => item.address === action.payload.address);
      if (index >= 0) {
        let newOrder = parseInt(action.payload.order)
        let oldOrder = index+1;
        state.list[index].name = action.payload.name;
        state.list[index].order = newOrder ?? 1;
        if(oldOrder !== newOrder){
          if(newOrder < oldOrder){
            for(let i = newOrder-1; i < oldOrder-1; i++){
              state.list[i].order = parseInt(state.list[i].order) + 1;
            }
          }else{
            for(let i = newOrder-1; i > oldOrder-1; i--){
              state.list[i].order = parseInt(state.list[i].order) - 1;
            }
          }
        }
        state.list.sort((a, b) => (a.order > b.order) ? 1 : -1)
      }
    },
  },
});

export const { addContact, updateContact, removeContact } = contactsSlice.actions;

export { contactsSlice };
