import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';

let initialState = {
  theme: 'dark',
  currency: {symbol:"$", value:"usd"},
  language: 'en',
  blockchains: [
    {
      name: 'Petrachor PTA',
      value: 1,
    },
  ],
};

const settingsSlice = createSlice({
  name: 'setting',
  initialState: initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      i18next.changeLanguage(state.language);
      AsyncStorage.setItem('i18nextLng', state.language);
    },
  },
});

// Actions
export const { setTheme, setCurrency, setLanguage } = settingsSlice.actions;

// Slice
export { settingsSlice };
