import React, { useEffect, useState }from 'react';
import { makeStyles, Popover, Tooltip } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { CopyIcon } from '../../reusable/icons';

const useStyles = makeStyles((theme) => ({
  popover: {
    maxWidth: 300,
    padding: 16,
    backgroundColor: 'rgba(124, 158, 175, 1)',
    borderRadius: 20,

    '& p': {
      fontSize: 12,
    },
  }
}));

const CopyToClipboardButton = ({ address, buttonClassName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorPopover, setAnchorPopover] = useState(null);


  const copyToClipboard = (value) => (event) => {
    setAnchorPopover(event.currentTarget);
    navigator.clipboard.writeText(value)
  };

  return (
    <>
      <Tooltip title={t('general.copy_to_clipboard')}>
        <IconButton
          size='medium'
          className={buttonClassName}
          onClick={copyToClipboard(address)}
        >
          <CopyIcon fontSize='inherit' />
        </IconButton>
      </Tooltip>

      <Popover
        // id={id}
        open={Boolean(anchorPopover)}
        anchorEl={anchorPopover}
        onClose={() => setAnchorPopover(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        // className={classes.popover}
        PaperProps={{
          classes: {
            root: classes.popover,
          },
          style: {},
        }}
      >
        <Typography style={{ whiteSpace: 'pre-line' }}>
          {t('accounts.address_copied_tip')}
        </Typography>
      </Popover>
    </>
  );
}

export default CopyToClipboardButton;
