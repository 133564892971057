import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const PetraButton = withStyles((theme) => ({
  root: {
    borderRadius: 34,
  },
  outlined: {
    borderWidth: 'medium',
    borderColor: theme.palette.action2.main,
    padding: '10px 30px',
    color: theme.palette.action2.main,
  },
  text: {
    backgroundColor: theme.palette.action2.main,
    color: 'white',
    borderWidth: 0,
    padding: '13px 30px',
    '&:hover': {
      backgroundColor: '#627c8a',
    },
    '&:disabled': {
      opacity: 0.7,
    },
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 'bold',
  },
}))((props) => <Button {...props} />);

export default PetraButton;
