import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeStyles } from '@material-ui/core';

import { selectContactsList } from '../../redux/selectors/contactsSelector';
import Contact from './Contact';

const useStyles = makeStyles((theme) => ({
  contactsHolder: {
    flexGrow: 1,
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const ContactList = ({ contacts, type = 1, onSelect, searchValue = '' }) => {
  const classes = useStyles();

  return (
    <div className={classes.contactsHolder}>
      {contacts !== null &&
        contacts.length > 0 &&
        contacts
          .filter((contact) =>
            searchValue === ''
              ? true
              : contact.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
          )
          .map((contact, id) => (
            <Contact key={contact.address} contact={contact} type={type} onSelect={onSelect} length={contacts.length} />
          ))}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  contacts: selectContactsList,
});

export default connect(mapStateToProps)(ContactList);
