import { createSelector } from 'reselect';

const selectContacts = (state) => state.contacts;

export const selectContactsList = createSelector([selectContacts], (contacts) => {
  if (contacts.list && contacts.list.length > 0) {
    const sortedContacts = [...contacts.list];
    sortedContacts.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
    return sortedContacts;
  } else {
    return [];
  }
});
