import { Box, FormHelperText } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AttentionIcon, InfoIcon } from './icons';

const useStyles = makeStyles((theme) => ({
  helperText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
  },
  infoIcon: {
    fill: theme.palette.action2.main,
  },
}));

const HelperText = ({ message, error }) => {
  const classes = useStyles();
  return (
    <FormHelperText error className={classes.helperText}>
      <AttentionIcon />
      <Box ml={1}>{message}</Box>
    </FormHelperText>
  );
};

export default HelperText;
