import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Typography from '@material-ui/core/Typography';

import PetraCard from '../../reusable/PetraCard';
import { selectAccountList } from '../../../redux/selectors/accountsSelector';
import {
  Box,
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import QRCode from 'qrcode.react';
const useStyles = makeStyles((theme) => ({
  qrBox: {
    background: 'white',
  },
  qrAddressMobile: { fontSize: '0.65rem' },
  qrAddress: { marginBottom: 24 },
}));
const ReceiveSection = ({ accounts, selectedAccount }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <div>
      <PetraCard>
        <Box my={3} display='flex' flexDirection='column' alignItems='center'>
          <Typography
            className={`${classes.qrAddress} ${
              isMobile ? classes.qrAddressMobile : null
            }`}
          >
            {selectedAccount.address}
          </Typography>
          <QRCode
            style={{ borderRadius: 20, marginBottom: 30 }}
            includeMargin={true}
            size={isMobile ? 150 : 300}
            value={selectedAccount.address}
          />
        </Box>
      </PetraCard>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
});

export default connect(mapStateToProps)(ReceiveSection);
