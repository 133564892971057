import { createSelector } from 'reselect';

const selectAccounts = (state) => state.accounts;

export const newAccountPassword = createSelector(
  [selectAccounts],
  (accounts) => accounts.newAccountPassword
);

export const selectAccountList = createSelector(
  [selectAccounts],
  (accounts) => accounts.list
);

export const selectAccountByAddress = (address) =>
  createSelector([selectAccountList], (list) =>
    list ? list.find((account) => account.address === address) : null
  );

export const selectTransactions = createSelector(
  [selectAccounts],
  (accounts) => accounts.transactions
);

export const selectTransactionsByAddress = (address) =>
  createSelector([selectTransactions], (transactions) =>
    transactions
      ? transactions.filter((transaction) => transaction.address === address)
      : null
  );

export const selectAssets = createSelector(
  [selectAccounts],
  (accounts) => accounts.assets
);

export const selectAssetsByAddress = (address) =>
  createSelector([selectAssets], (assets) =>
    assets ? assets.filter((asset) => asset.address === address) : null
  );
