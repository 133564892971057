import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { validate } from 'validate.js';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CircularProgress from '@material-ui/core/CircularProgress';
import fromExponential from 'from-exponential';
import { TX_FEE, OFFLINE_STATUS } from '../../../utils/constants';
import { AttentionIcon } from '../../reusable/icons';
import PetraCard from '../../reusable/PetraCard';
import PetraButton from '../../reusable/PetraButton';
import PetraInputLabel from '../../reusable/PetraLabel';
import PetraInput from '../../reusable/PetraInput';
import HelperText from '../../reusable/HelperText';
import PetraInputUpDown from '../../reusable/PetraInputUpDown';
import PetraTextField from '../../reusable/PetraTextField';
import BasicDialog from '../../reusable/BasicDialog';
import { selectAccountList } from '../../../redux/selectors/accountsSelector';
import { selectContactsList } from '../../../redux/selectors/contactsSelector';
import AddressSelectList from '../AddressSelectList';
import {
  sendPetra,
  getPrice,
  convertWeiToPetra,
  getAccountFromKeystore,
  convertPetraToWei,
  getTransactions,
  isHexStrict,
} from '../../../services/BlockchainService';
import QrReader from 'react-qr-reader';
import {
  updateAccountTransactions,
  addPendingTransaction,
} from '../../../redux/slices/accountsSlice';
import { selectCurrency } from '../../../redux/selectors/settingsSelector';
import { setLoading } from '../../../redux/slices/generalSlice';
import { LOADING_STATUS } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  leading: {
    marginRight: 25,
    color: theme.typography.subtitle1.color,
  },
  totalStepsLabel: {
    marginLeft: 4,
    opacity: 0.5,
    letterSpacing: 3,
  },
  firstStepTitle: {
    textAlign: 'center',
    fontWeight: 700,
    marginTop: 22,
  },
  blockchains: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 40,
  },
  blockchain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
    borderRadius: 15,
    width: '13%',

    [theme.breakpoints.down('xs')]: {
      width: '30%',
    },
  },
  blockchainSelected: {
    backgroundColor: 'rgba(255,255,255,0.1)',
  },
  blockchainName: {
    textAlign: 'center',
    fontSize: 14,
    marginTop: 15,
  },
  sendInputTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backButton: {
    cursor: 'pointer',
    padding: 5,
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.action2.main,
    marginRight: 20,
  },
  inputActionButton: {
    cursor: 'pointer',
    color: theme.palette.action2.main,
    marginRight: 15,
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
  },
  dialogTypeHolder: {
    height: 325,
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dialogTypeHolderInput: {
    height: 200,
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  inputLabel: {
    marginRight: 15,
  },
}));

const SendSection = ({
  accounts,
  contacts,
  account,
  updateAccountTransactions,
  addPendingTransaction,
  currency,
  handleOnSend,
  setLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [scannerStatus, setScannerStatus] = useState(false);
  const [sendInfo, setSendInfo] = useState({
    amount: '',
    sendTo: '',
    memo: '',
    password: '',
    fiat: '',
  });
  const [transactionInfo, setTransactionInfo] = useState({});
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [showCurrencyValue, setShowCurrencyValue] = useState(false);
  const [showTransactionDialog, setShowTransactionDialog] = useState(false);
  const [sending, setSending] = useState(false);
  const [petraPrice, setPetraPrice] = useState(false);
  const [calculatedPta, setcalculatedPta] = useState(0);

  const [dialogDimensions, setDialogDimensions] = useState({});
  let isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  let isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  let isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  let isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  let isVerticalHighLargeScreen = useMediaQuery('(min-height:750px)');
  useEffect(() => {
    if (isExtraLargeScreen) {
      setDialogDimensions({ height: '30vh', boxHeight: '35vh' });
      return;
    }
    if (isLargeScreen) {
      if (isVerticalHighLargeScreen) {
        setDialogDimensions({ height: '35vh', boxHeight: '40vh' });
        return;
      }
      setDialogDimensions({ height: '50vh', boxHeight: '40vh' });
      return;
    }
    if (isMediumScreen) {
      setDialogDimensions({ height: '25vh', boxHeight: '35vh' });
      return;
    }
    if (isMobile) {
      setDialogDimensions({ height: '45vh', boxHeight: '35vh' });
      return;
    }
  }, [isMobile, isLargeScreen, isExtraLargeScreen]);

  const currentPta = parseFloat(convertWeiToPetra(account.pta.toString()));

  const sendSchema = {
    amount: {
      presence: { allowEmpty: false, message: t('accounts.send.is_required') },
      numericality: function (
        value,
        attributes,
        attributeName,
        options,
        constraints
      ) {
        if (parseFloat(value) >= 0 && currentPta === 0) {
          return {
            equalTo: -1,
            message: function (
              value,
              attribute,
              validatorOptions,
              attributes,
              globalOptions
            ) {
              return validate.format('^%{num}' + t('accounts.send.no_funds'), {
                num: '',
              });
            },
          };
        } else {
          if (currentPta < TX_FEE) {
            return {
              equalTo: -1,
              message: `^${t('accounts.send.insuf_funds')}`,
            };
          } else {
            if (parseFloat(value) > currentPta - TX_FEE) {
              return {
                lessThan: currentPta - TX_FEE,
                message: `${t('accounts.send.max_funds')} ${
                  currentPta - TX_FEE
                } PTA`,
              };
            }
          }
        }
        return {
          greaterThan: 0,
          message: t('accounts.send.amount_positive'),
        };
      },
      length: function (
        value,
        attributes,
        attributeName,
        options,
        constraints
      ) {
        if (
          value > 0 &&
          (value + '').split('.').length > 1 &&
          (value + '').split('.')[1].length > 18
        ) {
          return {
            is: 1,
            message: t('accounts.send.amount_max_decimals'),
          };
        } else {
          return null;
        }
      },
    },
    sendTo: {
      presence: { allowEmpty: false, message: t('accounts.send.is_required') },
      length: function (
        value,
        attributes,
        attributeName,
        options,
        constraints
      ) {
        if (!isHexStrict(value) || (value + '').length !== 42) {
          return {
            is: -1,
            message: t('accounts.send.address_valid_error'),
          };
        } else {
          return null;
        }
      },
    },
    password: {
      presence: { allowEmpty: false, message: t('accounts.send.is_required') },
      length: { minimum: 6, message: t('accounts.send.password_min') },
    },
    fiat: {
      numericality: {
        greaterThan: 0,
        message: t('accounts.send.fiat_min_label'),
      },
    },
  };

  const [sendState, setSendState] = useState({
    isValid: false,
    errors: {},
    values: {},
    touched: {},
  });

  useEffect(() => {
    let errors = validate(sendState.values, sendSchema);
    setSendState((prevState) => ({
      ...prevState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [sendState.values]);

  useEffect(async () => {
    setPetraPrice(await getPrice(currency.value));
  }, []);

  const hasError = (field) =>
    !!(sendState.touched[field] && sendState.errors[field]);

  const handleChange = (event) => {
    if (event.persist) event.persist();
    let value =
      event.current === undefined ? event.target.value : event.current.value;
    let name =
      event.current === undefined ? event.target.name : event.current.name;

    setSendState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        [name]: value,
      },
      touched: {
        ...prevState.touched,
        [name]: true,
      },
    }));
  };

  const handleFiatChange = (event) => {
    handleChange(event);
    handleChange({
      target: {
        name: 'amount',
        value: (
          parseFloat(
            event.current == undefined
              ? event.target.value
              : event.current.value
          ) / petraPrice
        ).toString(),
      },
    });
    setcalculatedPta(
      parseFloat(
        event.current == undefined ? event.target.value : event.current.value
      ) / petraPrice
    );
  };

  const handleScan = (data) => {
    if (data) {
      handleChange({ target: { name: 'sendTo', value: data } });
      setScannerStatus(false);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const toggleScanner = () => {
    setScannerStatus(scannerStatus == true ? false : true);
  };

  const prepareOfflineTransactionsObject = async (acc, sendS) => {
    let timeStamp = (Date.now() / 1000).toFixed();
    const transaction = {
      txreceipt_status: OFFLINE_STATUS, // Random number of offline started transaction
      tempIndex: timeStamp,
      timeStamp: timeStamp,
      from: acc.address,
      to: sendS.values.sendTo,
      value: convertPetraToWei(sendS.values.amount),
    };
    addPendingTransaction({ address: acc.address, transaction });
    const transObj = {
      address: acc.address,
      keystore: acc.keystore,
      sendTo: sendS.values.sendTo,
      amount: sendS.values.amount,
      transaction,
    };
    let prevValue = await AsyncStorage.getItem('@offlineTransactions');
    prevValue = prevValue ? JSON.parse(prevValue) : [];
    AsyncStorage.setItem(
      '@offlineTransactions',
      JSON.stringify(prevValue.concat([transObj]))
    );
    setSending(false);
    setTransactionInfo({ txreceipt_status: '0' });

    setShowTransactionDialog(true);
  };

  const handleSend = async () => {
    setSending(true);
    if (sendState.isValid) {
      // If request failed and device is offline
      if (!navigator.onLine) {
        prepareOfflineTransactionsObject(account, sendState);
        return;
      }
      let accountDetails = await getAccountFromKeystore(
        account.keystore,
        sendState.values.password
      );
      if (!accountDetails) {
        setSending(false);
        setLoading({
          name: 'account/send/invalidPassword',
          status: LOADING_STATUS.FAILURE,
          message: t('accounts.send_opera_incorrect_password'),
        });
        return;
      }
      let transactions = await getTransactions(account);
      updateAccountTransactions({ address: account.address, transactions });
      let notFailedTransactions = account.transactions.filter(
        (x) =>
          x.txreceipt_status !== '0' &&
          x.from.toLowerCase() === account.address.toLowerCase()
      );
      let transaction = await sendPetra(
        account.address,
        sendState.values.sendTo,
        sendState.values.amount,
        accountDetails.privateKey,
        notFailedTransactions.length
      );
      if (transaction) {
        addPendingTransaction({ address: account.address, transaction });
      }
      setTransactionInfo(transaction);
      setShowTransactionDialog(true);
    } else {
      const touched = {};
      Object.entries(sendState.errors).forEach(
        ([key, value]) => (touched[key] = true)
      );
      setSendState((prevState) => ({
        ...prevState,
        touched: touched,
      }));
    }
    setSending(false);
  };

  const handleSelect = (address) => (event) => {
    handleChange({ target: { name: 'sendTo', value: address } });
    setShowAddressDialog(false);
  };

  const handleSetEntireBalance = () => {
    handleChange({
      target: { name: 'amount', value: (currentPta - TX_FEE).toString() },
    });
  };
  const handleSetCurrencyValue = (value) => {
    sendState.touched['fiat'] = false;
    sendState.errors['fiat'] = [];
    sendState.values['fiat'] = undefined;
    let calculatedDecimals = countDecimalPlaces(parseFloat(value) / petraPrice);
    let decimalPlaces = calculatedDecimals < 18 ? calculatedDecimals : 18;
    let amount = (parseFloat(value) / petraPrice)
      .toFixed(decimalPlaces)
      .toString();
    handleChange({
      target: {
        name: 'amount',
        value: amount,
      },
    });
    setcalculatedPta(0);
    setShowCurrencyValue(false);
  };

  const handleCurrencyValueClose = () => {
    sendState.touched['fiat'] = false;
    sendState.errors['fiat'] = [];
    sendState.values['fiat'] = undefined;
    setcalculatedPta(0);
    setShowCurrencyValue(false);
  };

  const handleTransactionDialogueClose = () => {
    setShowTransactionDialog(false);
    handleOnSend();
    history.push(`/account/${account.address}`);
  };

  const countDecimalPlaces = (number) => {
    let decimals = number.toString().split('.')[1].length;
    return decimals;
  };

  return (
    <div>
      <PetraCard>
        <Box display='flex'>
          <Typography
            component='span'
            className={`${classes.title} ${classes.leading}`}
          >
            {t('accounts.send_opera_label')}
          </Typography>
        </Box>

        {currentStep === 1 && (
          <Box mt={2}>
            <PetraInputLabel
              htmlFor='amount'
              className={classes.sendInputTitle}
            >
              <div className={classes.inputLabel}>
                {t('accounts.send_opera_ammount_label')}
              </div>
              <div
                className={classes.inputActionButton}
                onClick={() => setShowCurrencyValue(true)}
              >
                {`${t(
                  'accounts.send_opera_currency_label'
                )} ${currency.value?.toUpperCase()}`}
              </div>
              <div
                className={classes.inputActionButton}
                onClick={handleSetEntireBalance}
              >
                {t('accounts.send_opera_balance_label')}
              </div>
            </PetraInputLabel>
            <PetraInputUpDown
              id='amount'
              name='amount'
              inputProps={{ inputMode: 'decimal' }}
              value={fromExponential(sendState.values.amount) || ''}
              onChange={handleChange}
              error={hasError('amount')}
              helperText={
                hasError('amount') ? (
                  <HelperText message={sendState.errors.amount[0]} />
                ) : null
              }
            />

            <PetraInputLabel
              htmlFor='sendTo'
              className={classes.sendInputTitle}
            >
              <div className={classes.inputLabel}>
                {t('accounts.send_opera_send_to_label')}
              </div>
              <div
                className={classes.inputActionButton}
                onClick={() => setShowAddressDialog(true)}
              >
                {t('accounts.send_opera_address_label')}
              </div>
              <div
                className={classes.inputActionButton}
                onClick={() => toggleScanner()}
              >
                {t('accounts.send_opera_address_scan_label')}
              </div>
            </PetraInputLabel>
            <PetraTextField
              id='sendTo'
              name='sendTo'
              value={sendState.values.sendTo || ''}
              onChange={handleChange}
              error={hasError('sendTo')}
              helperText={
                hasError('sendTo') ? (
                  <HelperText message={sendState.errors.sendTo[0]} />
                ) : null
              }
            />
            {scannerStatus == true && (
              <Grid Grid container justify='center'>
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '50%' }}
                />
              </Grid>
            )}

            {/* <PetraInputLabel htmlFor='memo'>
              {t('accounts.send_opera_memo_label')}
            </PetraInputLabel>
            <PetraTextField
              id='memo'
              name='memo'
              value={sendInfo.memo}
              onChange={handleChange}
            /> */}

            <PetraInputLabel
              htmlFor='password'
              className={classes.sendInputTitle}
            >
              {t('accounts.send_opera_password_label')}
            </PetraInputLabel>
            <PetraInput
              id='password'
              name='password'
              value={sendState.values.password || ''}
              ispassword='true'
              onChange={handleChange}
              error={hasError('password')}
              helperText={
                hasError('password') ? (
                  <HelperText message={sendState.errors.password[0]} />
                ) : null
              }
            />

            <Box mt={4} mb={4} display='flex' justifyContent='center'>
              <PetraButton
                onClick={handleSend}
                disabled={sending}
                startIcon={
                  sending && (
                    <CircularProgress size={20} style={{ marginRight: 10 }} />
                  )
                }
              >
                {t('accounts.send_opera_submit_button')}
              </PetraButton>
            </Box>
          </Box>
        )}

        <BasicDialog
          open={showTransactionDialog}
          onClose={handleTransactionDialogueClose}
          width={550}
        >
          <Box
            my={4}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            {transactionInfo && transactionInfo.txreceipt_status !== '0' ? (
              <Typography>{t('accounts.send_opera_success')}</Typography>
            ) : navigator.onLine ? (
              <Typography>{t('accounts.send_opera_failed')}</Typography>
            ) : (
              <Typography>{t('accounts.send_opera_offline')}</Typography>
            )}
          </Box>
        </BasicDialog>

        <BasicDialog
          open={showAddressDialog}
          onClose={() => setShowAddressDialog(false)}
          title={t('accounts.address_select_title')}
          width={750}
          height={'45vh'}
        >
          <Box className={classes.dialogTypeHolder}>
            <AddressSelectList
              onSelect={handleSelect}
              selectedAccount={account}
              petraPrice={petraPrice}
            />
          </Box>
        </BasicDialog>

        <BasicDialog
          open={showCurrencyValue}
          onClose={handleCurrencyValueClose}
          height={dialogDimensions.height}
          boxHeight={dialogDimensions.boxHeight}
          title={`${t(
            'accounts.send_opera_currency_label'
          )} ${currency.value.toUpperCase()}`}
        >
          <Box className={classes.dialogTypeHolderInput}>
            <PetraInputLabel
              htmlFor='amount'
              className={classes.sendInputTitle}
            >
              <div>{t('accounts.send_opera_fiat_label')}</div>
            </PetraInputLabel>
            <PetraInputUpDown
              id='fiat'
              name='fiat'
              value={sendState.values.fiat || ''}
              onChange={handleFiatChange}
              error={hasError('fiat')}
              helperText={
                hasError('fiat') ? (
                  <HelperText message={sendState.errors.fiat[0]} />
                ) : null
              }
            />
            <Typography variant='caption'>
              {`PTA:${
                calculatedPta > 0 ? `${fromExponential(calculatedPta)}` : ''
              }`}
            </Typography>
            {hasError('amount') &
            (parseFloat(sendState.values.fiat) > 0) &
            !hasError('fiat') ? (
              <HelperText message={sendState.errors.amount[0]} />
            ) : null}
            <Box display='flex' justifyContent='center'>
              <PetraButton
                onClick={() => handleSetCurrencyValue(sendState.values.fiat)}
              >
                {t('accounts.send_opera_submit_button')}
              </PetraButton>
            </Box>
          </Box>
        </BasicDialog>
      </PetraCard>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
  contacts: selectContactsList,
  currency: selectCurrency,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountTransactions: (value) =>
    dispatch(updateAccountTransactions(value)),
  addPendingTransaction: (value) => dispatch(addPendingTransaction(value)),
  setLoading: (data) => dispatch(setLoading(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendSection);
