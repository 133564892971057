import React, { useRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import PetraTextField from './PetraTextField';
import { SearchIcon } from '../reusable/icons/';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  icon: {
    stroke: theme.palette.action2.main
  }
}));


const PetraSearch = ({ value, onChange }) => {
  const classes = useStyle();
  const searchInput = useRef(null);
  return (
    <PetraTextField
      inputRef={searchInput}
      value={value}
      onChange={onChange}
      style={{ marginTop: 12 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <IconButton
              aria-label='toggle password visibility'
              edge='start'
              disableRipple
              size='small'
              onClick={() => searchInput.current.focus()}
            >
              <SearchIcon className={classes.icon} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PetraSearch;
