import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  title: {
    background: '#7C9EAF',
    textAlign: 'center',
    padding: 18,
  },
  label: {
    color: 'rgba(255,255,255,1)',
    fontWeight: 600,
    padding: 20,
  },
  closeIcon: {
    float: 'right',
    marginTop: 2,
    color: '#FFFFFF',
  },
  outOfStepLabel: {
    color: 'rgba(255,255,255,0.5)',
    fontWeight: 600,
  },
  dialogContainer: {
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    padding: 20,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

function BasicDialog(props) {
  const classes = useStyle();
  const {
    onClose,
    open,
    title,
    titleStep,
    maxWidth = 'sm',
    height,
    width,
    boxHeight,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const titleSteps = () => {
    if (titleStep)
      return (
        <Box component='span'>
          <Box component='span' ml={3}>
            {titleStep.split('/')[0]}
          </Box>
          <Typography component='span' className={classes.outOfStepLabel}>
            {'/' + titleStep.split('/')[1]}
          </Typography>
        </Box>
      );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={open}
      maxWidth={maxWidth}
      fullWidth
      className={classes.dialog}
      PaperProps={{
        style: { borderRadius: 15, maxWidth: width ?? 650, height: height },
      }}
    >
      <DialogTitle className={classes.title}>
        <Typography component='span' className={classes.label} variant='body1'>
          {title}
          {titleSteps()}
        </Typography>
        <IconButton
          onClick={handleClose}
          size='small'
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        className={classes.dialogContainer}
        style={{
          height: boxHeight ?? null,
        }}
      >
        {props.children}
      </Box>
    </Dialog>
  );
}

BasicDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  titleStep: PropTypes.string,
  render: PropTypes.func,
};

export default BasicDialog;
