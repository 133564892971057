import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import AccountList from './AccountList';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContactList from '../Contacts/ContactList';
import { selectAccountList } from '../../redux/selectors/accountsSelector';
import { selectContactsList } from '../../redux/selectors/contactsSelector';

const useStyle = makeStyles((theme) => ({
  dialogTypeTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingTop: 15,
  },
}));

const AddressSelectList = ({
  onSelect,
  selectedAccount,
  petraPrice,
  accounts,
  contacts,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.dialogTypeTitle}>
        {t('accounts.address_select_accounts')}
      </Typography>
      <AccountList
        onSelect={onSelect}
        selectedAccount={selectedAccount}
        type={2}
        petraPrice={petraPrice}
      />
      <Typography className={classes.dialogTypeTitle}>
        {t('accounts.address_select_contacts')}
      </Typography>
      <ContactList onSelect={onSelect} />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
  contacts: selectContactsList,
});

export default connect(mapStateToProps)(AddressSelectList);
