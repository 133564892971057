import Web3 from 'web3';
import * as AccountData from '../data/AccountData';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { browserCanSave } from '../utils/BrowserCheck';
import fromExponential from 'from-exponential';
// const web3 = new Web3("process.env.GANACHE_URL"); //Local eth
const web3 = new Web3(process.env.REACT_APP_PETRA_URL); //Petra node

const TRANSACTION_RESPONSE = Object.freeze({
  OK: 'OK',
});

export const createNewAccount = async (password) => {
  try {
    let returnSignal = [true];
    let account = await web3.eth.accounts.createBLS();
    let accountWithKeyStore;
    if (browserCanSave()) {
      accountWithKeyStore = web3.bls.save(
        account.address,
        account.privateKey,
        password,
        returnSignal
      );
    } else {
      accountWithKeyStore = web3.bls.generateWallet(
        account.privateKey,
        password,
        account.address
      );
    }
    return {
      address: account.address,
      privateKey: account.privateKey,
      keystore: accountWithKeyStore,
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getAccountBalance = async (account) => {
  try {
    let balance = await web3.eth.getBalance(account.address);
    return balance;
  } catch (err) {
    return null;
  }
};

export const getAccountFromPrivateKey = async (privateKey, password) => {
  try {
    let returnSignal = [true];
    let { address } = await web3.eth.accounts.privateKeyToAccountBLS(
      privateKey
    );
    let accountWithKeyStore = web3.bls.generateWallet(
      privateKey,
      password,
      address
    );
    console.log({ address });
    return { address, keystore: accountWithKeyStore };
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getAccountFromKeystore = async (keyStore, password) => {
  try {
    let crypto = keyStore.crypto ?? keyStore;
    let privateKey = web3.bls.decryptPrivateKey(
      crypto.cipherparams,
      crypto.ciphertext,
      password,
      crypto.kdfparams.salt
    );
    let { address } = await web3.eth.accounts.privateKeyToAccountBLS(
      privateKey
    );
    if (address !== keyStore.address) {
      return;
    }
    return { address, keystore: crypto, privateKey };
  } catch (err) {
    console.log(err);
    return;
  }
};

export const sendPetra = async (
  from,
  to,
  amount,
  privateKey,
  transactionCount
) => {
  try {
    let fromNodeTransactionCount = await web3.eth.getTransactionCount(from);
    let transaction = {
      from: from,
      to: to,
      value: web3.utils.toHex(web3.utils.toWei(amount, 'ether')),
      gas: web3.utils.toHex(21000),
      gasPrice: web3.utils.toHex(20000000000), // converts the gwei price to wei
      nonce:
        fromNodeTransactionCount > transactionCount
          ? fromNodeTransactionCount
          : transactionCount,
    };
    let signed = await web3.bls.sign(privateKey, transaction);
    let receipt = await sendRawTransactionPetra('0x' + signed);
    let hasError = hasTransactionError(receipt);
    transaction.txreceipt_status = hasError ? '0' : '-1';
    transaction.hash = hasError ? undefined : receipt?.result;
    transaction.timeStamp = (Date.now() / 1000).toFixed();
    if (!hasError) {
      transaction.blockNumber = await getBlockNumber();
    }
    return transaction;
  } catch (err) {
    return null;
  }
};
const hasTransactionError = (receipt) => {
  if (!receipt) return true;
  return receipt.result.includes('0x0000') || !receipt.result;
};
export const getTransactions = async (account) => {
  try {
    let response = await fetch(
      `${process.env.REACT_APP_EXPLORER_URL}/api?module=account&action=txlist&address=${account.address}`
    );
    let transactions = await response.json();
    if (transactions.message !== TRANSACTION_RESPONSE.OK) return;
    return transactions.result;
  } catch (err) {
    return null;
  }
};
export const convertWeiToPetra = (amount) => {
  try {
    if (web3.utils.isHexStrict(amount)) {
      const doubled = web3.utils.toBN(amount);
      amount = doubled.toString();
    }
    amount = fromExponential(amount);
    return web3.utils.fromWei(parseFloat(amount) > 0 ? amount + '' : '0');
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const convertPetraToWei = (amount) => {
  var value = web3.utils.toWei(amount);
  return web3.utils.toWei(amount);
};

export const downloadKeystore = (keystore) => {
  const fileName =
    'UTC--' +
    new Date().toISOString().replace(/:/g, '-') +
    '--' +
    keystore.address;
  web3.bls.download(
    JSON.stringify(keystore),
    fileName,
    'application/octet-stream'
  );
};

const sendRawTransactionPetra = async (hash) => {
  try {
    let response = await fetch(`${process.env.REACT_APP_PETRA_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'eth_sendRawTransaction',
        params: [hash],
        id: 1,
      }),
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return;
  }
};
export const getPrice = async (currency) => {
  try {
    currency = currency.toLowerCase();
    var queryString = `https://api.coingecko.com/api/v3/simple/price?ids=petrachor&vs_currencies=${currency}`;
    let response = await fetch(queryString);
    let data = await response.json();
    if (data.petrachor[currency] === undefined) return 0;
    await AsyncStorage.setItem('@price_' + currency, data.petrachor[currency]);
    return data.petrachor[currency];
  } catch (err) {
    console.log(err);
    return await AsyncStorage.getItem('@price_' + currency);
  }
};
export const getBlockNumber = async () => {
  return await web3.eth.getBlockNumber();
};

export const isHexStrict = (address) => {
  return web3.utils.isHexStrict(address);
};
