import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core';

const PetraTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);

export default PetraTabs;
