import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as HomeIcon } from '../reusable/icons/img/home-icon.svg';
import { ReactComponent as WalletIcon } from '../reusable/icons/img/wallet-icon.svg';
import { ReactComponent as ContactIcon } from '../reusable/icons/img/contacts-icon.svg';
import { ReactComponent as SettingsIcon } from '../reusable/icons/img/settings-icon.svg';
import { ReactComponent as LogoIcon } from '../reusable/icons/img/logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { red } from '@material-ui/core/colors';

import { menuOptions } from '../../utils/constants';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      margin: '20px',
    },
    height: '100vh',
    borderRadius: 8,
    backgroundColor: theme.palette.background.sidebar,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    [theme.breakpoints.only('sm')]: {
      width: 130,
      flexShrink: 0,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  drawerPaper: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
    [theme.breakpoints.only('sm')]: {
      width: 130,
    },
    borderRight: 0,
    backgroundColor: theme.palette.background.defaultGradient,
  },
  list: {
    [theme.breakpoints.up('md')]: {
      padding: 30,
    },
  },
  listItem: {
    borderRadius: 10,
    paddingLeft: 17,
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 20,
    width: '200px',
    height: '50px',
    '& > *': {
      fill: 'rgba(175, 175, 175)',
    },
    '&:hover > *': {
      fill: 'rgba(184, 107, 12)',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'contents',
      textAlign: 'center',
    },
  },
  listItemIcon: {
    [theme.breakpoints.only('sm')]: {
      paddingLeft: 47,
    },
  },
  listItemText: {
    color: theme.palette.extension1.sidebar,
    opacity: 0.5,
  },
  settingsMenuOption: {
    borderRadius: 10,
    paddingLeft: 17,
    paddingBottom: 12,
    marginBottom: 20,
    width: '200px',
    height: '50px',
    position: 'fixed',
    bottom: 20,
    '& > *': {
      fill: 'rgba(175, 175, 175)',
    },
    '&:hover > *': {
      fill: 'rgba(184, 107, 12)',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'contents',
      textAlign: 'center',
    },
  },
  icon: {
    width: '35px',
    height: '35px',
  },
  logoBox: {
    paddingLeft: 30,
    paddingTop: 20,
    overflow: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 50,
      marginBottom: 50,
    },
  },
  listItemMobile: {
    display: 'inline-block',
    paddingLeft: 17,
    width: '25%',
    '& > *': {
      fill: 'rgba(175, 175, 175)',
    },
    '&:hover > *': {
      fill: 'rgba(184, 107, 12)',
    },
    textAlign: 'center',
  },
  listItemMobileIcon: {
    display: 'inline-block',
  },
  isActiveIcon: {
    fill: 'rgba(184, 107, 12)',
  },
  isActiveText: {
    fontWeight: 700,
    opacity: 1,
  },
}));

const Menu = (props) => {
  const menu = menuOptions;
  const { window } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const icons = (type) => {
    switch (type) {
      case 'Home':
        return <HomeIcon className={classes.icon} />;
      case 'Accounts':
        return <WalletIcon className={classes.icon} />;
      case 'Contacts':
        return <ContactIcon className={classes.icon} />;
      case 'Settings':
        return <SettingsIcon className={classes.icon} />;
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation='css'>
        <Drawer container={container} variant='persistent' anchor='bottom' open>
          <Box>
            <List>
              {menu.map((menu, index) => (
                <ListItem
                  key={'menu-xs' + index}
                  className={classes.listItemMobile}
                  component='a'
                  href={`${document.location.origin}${menu.path}`}
                  className={`${classes.listItemMobile} ${
                    location.pathname.includes(menu.routeTitle.toLowerCase()) ||
                    (menu.routeTitle === 'home' && location.pathname === '/')
                      ? classes.isActiveMenu
                      : null
                  }`}
                  onClick={(event) => {
                    history.push(menu.path);
                    event.preventDefault();
                  }}
                >
                  <ListItemIcon className={classes.listItemMobileIcon}>
                    {icons(menu.title)}
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation='css'>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          <Box className={classes.root}>
            <Box className={classes.logoBox}>
              <LogoIcon style={{ width: 200, height: 30 }} />
            </Box>
            <List className={classes.list}>
              {menu.map((menu, index) => (
                <ListItem
                  button
                  component='a'
                  href={`${document.location.origin}${menu.path}`}
                  key={'menu' + index}
                  className={
                    menu.title !== 'Settings'
                      ? classes.listItem
                      : classes.settingsMenuOption
                  }
                  onClick={(event) => {
                    history.push(menu.path);
                    event.preventDefault();
                  }}
                >
                  <ListItemIcon
                    className={`${classes.listItemIcon} ${
                      location.pathname.includes(
                        menu.routeTitle.toLowerCase()
                      ) ||
                      (menu.routeTitle === 'home' && location.pathname === '/')
                        ? classes.isActiveIcon
                        : null
                    }`}
                  >
                    {icons(menu.title)}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      variant='subtitle2'
                      className={`${classes.listItemText} ${
                        location.pathname.includes(
                          menu.routeTitle.toLowerCase()
                        ) ||
                        (menu.routeTitle === 'home' &&
                          location.pathname === '/')
                          ? classes.isActiveText
                          : null
                      }`}
                    >
                      {t('menu.' + menu.title)}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Menu;
