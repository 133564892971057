import { combineReducers } from 'redux';

import { generalSlice } from './slices/generalSlice';
import { settingsSlice } from './slices/settingsSlice';
import { contactsSlice } from './slices/contactsSlice';
import { accountsSlice } from './slices/accountsSlice';

const rootReducer = combineReducers({
  general: generalSlice.reducer,
  accounts: accountsSlice.reducer,
  settings: settingsSlice.reducer,
  contacts: contactsSlice.reducer,
});

export default rootReducer;
