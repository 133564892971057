import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AsyncStorage from '@react-native-async-storage/async-storage';

import PetraCard from '../reusable/PetraCard';
import BasicDialog from '../reusable/BasicDialog';
import CreateWalletButton from './createWallet/CreateWalletButton';
import RestoreAccount from './restoreAccount/RestoreAccount';
import RemoveAccounts from './removeAccounts/RemoveAccounts';
import PetraAmount from '../reusable/PetraAmount';
import AccountList from '../Accounts/AccountList';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { selectAccountList } from '../../redux/selectors/accountsSelector';
import PetraTitle from '../reusable/PetraTitle';
import { ReactComponent as WindowsIcon } from '../reusable/icons/img/windows-icon.svg';
import { ReactComponent as AppleIcon } from '../reusable/icons/img/apple-icon.svg';
import { ReactComponent as IosIcon } from '../reusable/icons/img/ios-icon.svg';
import { ReactComponent as LinuxIcon } from '../reusable/icons/img/linux-icon.svg';
import { ReactComponent as AndroidIcon } from '../reusable/icons/img/android-icon.svg';
import { selectCurrency } from '../../redux/selectors/settingsSelector';
import { getPrice, convertWeiToPetra } from '../../services/BlockchainService';

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      paddingBottom: 100,
    },
  },
  walletActions: {
    marginTop: 9,
    marginBottom: 10,
    marginLeft: 10,
  },
  walletAction: {
    maxWidth: '33%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      filter: 'brightness(90%)',
    },
  },
  actionHolder: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  amountHolder: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginBottom: 20,
    },
  },
  list: {
    marginTop: 10,
    display: 'inline-block',
  },
  listItem: {
    float: 'left',
    borderRadius: 10,
    paddingLeft: 17,
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 20,
    width: '200px',
    height: '50px',
    '& > *': {
      fill: 'rgba(175, 175, 175)',
    },
    '&:hover > *': {
      fill: 'rgba(124, 158, 175)',
    },
  },
  listItemText: {
    color: theme.palette.extension1.sidebar,
  },
  icon: {
    width: '35px',
    height: '35px',
  },
  steps: {
    color: theme.palette.extension1.sidebar,
  },
  stepsLink: {
    color: theme.typography.subtitle1.color,
  },
}));

const Home = ({ accounts, currency }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedOs, setSelectedOs] = useState({ title: '' });
  const [totalPta, setTotalPta] = useState(0);
  const [petraPrice, setPetraPrice] = useState(0);
  const [installedOnDevice, setInstalledOnDevice] = useState(false);

  useEffect(() => {
    let sum = 0;
    accounts.map((account) => {
      sum += parseFloat(account.pta);
    });
    setTotalPta(sum);

    if (
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone === true
    ) {
      setInstalledOnDevice(true);
    } else {
      window.addEventListener('beforeinstallprompt', (event) => {
        // event.prompt(); this can be called on a mouse click event
        setInstalledOnDevice(!Boolean(event));
        event.userChoice.then((choice) => {
          if (choice.outcome === 'accepted') {
            setInstalledOnDevice(true);
          }
        });
      });
      window.addEventListener('appinstalled', () => {
        setInstalledOnDevice(true);
      });
    }
  }, [accounts]);

  useEffect(async () => {
    let price = await getPrice(currency.value);
    setPetraPrice(price);
  }, []);

  const oss = [
    {
      title: 'Windows',
      step1Browser: t('home.browser_chrome'),
      step2Go: t('home.step2-go'),
      step2Click: t('home.step2-chrome-click'),
      step2Where: t('home.step2-chrome-where'),
      step3ClickOn: t('home.os_install'),
    },
    {
      title: 'macOS',
      step1Browser: t('home.browser_chrome'),
      step2Go: t('home.step2-go'),
      step2Click: t('home.step2-chrome-click'),
      step2Where: t('home.step2-chrome-where'),
      step3ClickOn: t('home.os_install'),
    },
    {
      title: 'Linux',
      step1Browser: t('home.browser_chrome'),
      step2Go: t('home.step2-go'),
      step2Click: t('home.step2-chrome-click'),
      step2Where: t('home.step2-chrome-where'),
      step3ClickOn: t('home.os_install'),
    },
    {
      title: 'iOS',
      step1Browser: t('home.browser_safari'),
      step2Go: t('home.step2-click-the'),
      step2Click: t('home.step2-safari-click'),
      step2Where: t('home.step2-safari-where'),
      step3ClickOn: t('home.os_add'),
    },
    {
      title: 'Android',
      step1Browser: t('home.browser_chrome'),
      step2Go: t('home.step2-click'),
      step2Click: t('home.step2-chrome-click'),
      step2Where: t('home.step2-android-where'),
      step3ClickOn: t('home.os_install_android'),
    },
  ];
  const icons = (type) => {
    switch (type) {
      case 'Windows':
        return <WindowsIcon className={classes.icon} />;
        break;
      case 'macOS':
        return <AppleIcon className={classes.icon} />;
        break;
      case 'iOS':
        return <IosIcon className={classes.icon} />;
        break;
      case 'Linux':
        return <LinuxIcon className={classes.icon} />;
        break;
      case 'Android':
        return <AndroidIcon className={classes.icon} />;
        break;
    }
  };

  const handleSelectOs = (os) => {
    setSelectedOs(os);
    setOpen(true);
  };

  return (
    <>
      <div className={classes.homeContainer}>
        <PetraTitle title={t('menu.Home')} />

        <PetraCard>
          <Grid container className={classes.walletActions}>
            <Grid
              className={classes.actionHolder}
              item
              xs={12}
              md={9}
              container
              direction='row'
              justify='flex-start'
              alignItems='center'
              spacing={2}
            >
              <Grid item className={classes.walletAction}>
                <CreateWalletButton />
              </Grid>
              <Grid item className={classes.walletAction}>
                <RestoreAccount />
              </Grid>
              <Grid item className={classes.walletAction}>
                <RemoveAccounts />
              </Grid>
            </Grid>
            <Grid
              className={classes.amountHolder}
              item
              xs={12}
              md={3}
              container
              direction='column'
              justify='space-between'
            >
              <PetraAmount
                showInfo={false}
                rawPta={totalPta}
                amount={convertWeiToPetra(totalPta.toString())}
                dollar={convertWeiToPetra(totalPta.toString()) * petraPrice}
              />
            </Grid>
          </Grid>
        </PetraCard>
        <Box mt={3}></Box>

        <PetraTitle
          title={t('home.accounts_title')}
          badgeCount={accounts.length}
        />

        <AccountList petraPrice={petraPrice} />

        <Box
          style={{
            textAlign: 'center',
            marginTop: 10,
            display: installedOnDevice ? 'none' : '',
          }}
        >
          <List className={classes.list}>
            {oss.map((os, index) => (
              <ListItem
                button
                key={'os' + index}
                className={classes.listItem}
                onClick={() => {
                  handleSelectOs(os);
                }}
              >
                <ListItemIcon>{icons(os.title)}</ListItemIcon>
                <ListItemText>
                  <Typography
                    variant='subtitle1'
                    className={classes.listItemText}
                  >
                    {os.title}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Box>
      </div>

      <BasicDialog
        open={open}
        onClose={() => setOpen(false)}
        title={selectedOs.title + ' ' + t('home.installation')}
      >
        <Box style={{ margin: 10 }}>
          <Typography variant='subtitle1' className={classes.steps}>
            {t('home.step1')}{' '}
            <Link href='/' className={classes.stepsLink}>
              {t('home.step1_wallet')}
            </Link>{' '}
            {selectedOs.step1Browser}
          </Typography>
          <Typography variant='subtitle1' className={classes.steps}>
            {t('home.step2')} {selectedOs.step2Go}{' '}
            <b>{selectedOs.step2Click}</b> {selectedOs.step2Where}
          </Typography>
          <Typography variant='subtitle1' className={classes.steps}>
            {t('home.step3')} “{selectedOs.step3ClickOn}”
          </Typography>
        </Box>
      </BasicDialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
  currency: selectCurrency,
});

export default connect(mapStateToProps)(Home);
