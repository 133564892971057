import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';

import { DirectionDownIcon } from '../reusable/icons/';

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    marginTop: 4,
    marginBottom: 18,
    backgroundColor: theme.palette.type === 'light' ? '#E8E8E9' : '#323A41',

    '& > div': {
      padding: '15px 12px',
      fontSize: 14,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.action2.main} !important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.action2.main} !important`,
    },
    '& .MuiSelect-iconOutlined': {
      top: 'calc(50% - 4px)',
      right: 25,
    },
  },
  menuItem: {
    fontSize: 14
  },
}));

const PetraSelect = ({ name, value, values, onChange }) => {
  const classes = useStyle();
  return (
    <Select
      name={name}
      className={classes.root}
      fullWidth
      variant='outlined'
      id='blockchain-select'
      value={value}
      onChange={onChange}
      IconComponent={DirectionDownIcon}
    >
      {values.map((item, i) => (
        <MenuItem className={classes.menuItem} key={i} selected={i === value} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PetraSelect;
