import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { selectTheme } from './redux/selectors/settingsSelector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

const Theme = (props) => {
  const isLight = props.theme === 'light';

  const theme = createMuiTheme({
    palette: {
      type: props.theme,
      background: {
        paper: isLight ? '#E8E8E9' : '#1F262C',
        defaultGradient: isLight ? '#F4F4F4' : '#222A31',
        sidebar: isLight ? '#FFFFFF' : '#1F262C',
      },
      primary: {
        light: '#C0C6CB',
        main: '#FFFFFF',
        dark: '#030200',
      },
      themeColor: {
        main: 'rgba(124, 158, 175,0.2)',
      },
      action2: {
        main: '#7C9EAF',
        error: '#EB5757',
      },
      extension1: {
        main: isLight ? '#000000' : '#FFFFFF',
        sidebar: isLight ? '#000000' : '#FFFFFF',
      },
      tabButtonIcon: {
        selected0: '#B86B0C',
        selected1: '#4D6A69',
        selected2: '#7C9EAF',
        default: '#FFFFFF',
      },
      accountSectionButton: {
        selected: '#8B8C8E',
        default: 'rgba(255,255,255,0.1)',
      },
    },
    typography: {
      fontFamily: 'Poppins',
      // fontSize: 16,
      h5: {
        fontWeight: 700,
        color: isLight ? '#435346' : '#FFFFFF',
      },
      subtitle1: {
        fontWeight: 700,
        color: '#7C9EAF',
      },
      subtitle2: {
        fontWeight: 100,
        color: '#B86B0C',
      },
    },
  });
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

const mapStateToProps = createStructuredSelector({
  theme: selectTheme,
});

export default connect(mapStateToProps)(Theme);
