import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PetraTextField from '../reusable/PetraTextField';
import PetraSearch from '../reusable/PetraSearch';
import PetraTitle from '../reusable/PetraTitle';
import BasicDialog from '../reusable/BasicDialog';
import PetraLabel from '../reusable/PetraLabel';
import PetraSelect from '../reusable/PetraSelect';
import PetraInputUpDown from '../reusable/PetraInputUpDown';
import PetraButton from '../reusable/PetraButton';
import { selectAccountList } from '../../redux/selectors/accountsSelector';
import {
  selectBlockchains,
  selectCurrency,
} from '../../redux/selectors/settingsSelector';
import AccountList from './AccountList';
import { getPrice } from '../../services/BlockchainService';
const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingBottom: 0,
    height: '100vh',
    overflow: 'hidden',
    boxSizing: 'border-box',

    [theme.breakpoints.down('xs')]: {
      paddingBottom: 70,
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    marginBottom: 6,
  },
  accountCount: {
    fontSize: 10,
    height: 15,
    marginLeft: 5,
  },
  btnNewAccount: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addIcon: {
    padding: '3px 12px',
    background: theme.palette.action2.main,
    borderRadius: 34,
  },
  accountsHolder: {
    flexGrow: 1,
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  bottomFade: {
    zIndex: 99,
    position: 'fixed',
    bottom: '0%',
    height: 40,
    width: '100%',
    pointerEvents: 'none',
    backgroundImage: `linear-gradient(to bottom, transparent, ${theme.palette.background.defaultGradient})`,
  },
}));

const Accounts = ({ accounts, blockchains, currency }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openNewAccountDialog, setOpenNewAccountDialog] = useState(false);
  const [accountInfo, setAccountInfo] = useState({
    address: '',
    name: '',
    blockchain: '',
    order: 1,
  });
  const [searchValue, setSearchValue] = useState('');
  const [petraPrice, setPetraPrice] = useState(0);

  const handleChange = (field) => (event) => {
    setAccountInfo({
      ...accountInfo,
      [field]: event.target ? event.target.value : event.current.value,
    });
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  useEffect(async () => {
    let price = await getPrice(currency.value);
    setPetraPrice(price);
  }, []);

  return (
    <div className={classes.accountsContainer}>
      <PetraTitle
        title={t('accounts.title')}
        badgeCount={accounts.length}
        // handleAdd={() => setOpenNewAccountDialog(true)}
        // addLabel={t('accounts.new_label')}
      />

      <PetraSearch onChange={handleSearch} />

      <div className={classes.accountsHolder}>
        <AccountList searchValue={searchValue} petraPrice={petraPrice} />
      </div>

      <div className={classes.bottomFade}></div>

      <BasicDialog
        open={openNewAccountDialog}
        onClose={() => setOpenNewAccountDialog(false)}
        title={t('dialogs.new_account.title')}
      >
        <PetraLabel>{t('dialogs.new_account.address_label')}</PetraLabel>
        <PetraTextField
          value={accountInfo.address}
          onChange={handleChange('address')}
        />

        <PetraLabel>{t('dialogs.new_account.name_label')}</PetraLabel>
        <PetraTextField
          value={accountInfo.name}
          onChange={handleChange('name')}
        />

        <PetraLabel>{t('dialogs.new_account.blockchain_label')}</PetraLabel>
        <PetraSelect
          values={blockchains}
          value={accountInfo.blockchain}
          onChange={handleChange('blockchain')}
        />

        <PetraLabel>{t('dialogs.new_account.order_label')}</PetraLabel>
        <PetraInputUpDown
          value={accountInfo.order}
          onChange={handleChange('order')}
        />

        <Box mt={2} display='flex' justifyContent='center'>
          <PetraButton>{t('dialogs.new_account.save_button')}</PetraButton>
        </Box>
      </BasicDialog>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
  blockchains: selectBlockchains,
  currency: selectCurrency,
});

export default connect(mapStateToProps)(Accounts);
