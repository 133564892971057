import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PetraChip from '../../reusable/PetraChip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectTransactionsByAddress,
  selectAccountList,
} from '../../../redux/selectors/accountsSelector';

import { selectContactsList } from '../../../redux/selectors/contactsSelector';
import { convertWeiToPetra } from '../../../services/BlockchainService';
import ContactCreateDialog from '../../Contacts/ContactCreateDialog';

const useStyles = makeStyles((theme) => ({
  sectionButton: {
    borderRadius: 12,
    paddingTop: 10,
    paddingBottom: 10,
    textTransform: 'capitalize',
    fontWeight: 700,
    color: 'white',
    marginRight: 10,

    '&:hover': {
      opacity: 1,
    },
  },
  selectedButton: {
    backgroundColor: theme.palette.accountSectionButton.selected,

    '&:hover': {
      backgroundColor: theme.palette.accountSectionButton.selected,
      opacity: 0.9,
    },
  },
  defaultButton: {
    backgroundColor: theme.palette.accountSectionButton.default,
    opacity: 0.9,

    '&:hover': {
      backgroundColor: theme.palette.accountSectionButton.default,
      opacity: 0.8,
    },
  },
  // Transactions
  transactionsHolder: {
    marginTop: 17,
  },
  transactionsContainer: {
    borderRadius: 12,
    paddingBottom: 50,
  },
  transactionsHeader: {
    fontSize: 11,
    fontWeight: 700,
    paddingTop: 13,
    paddingBottom: 13,
    lineHeight: '1rem',
    borderBottom: `2px solid rgba(184, 107, 12, 0.2)`,
  },
  transactionsRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  transactionsItem: {
    fontSize: 13,
    lineHeight: '1rem',
    paddingTop: 13,
    paddingBottom: 13,
    border: 'none',
    whiteSpace: 'nowrap',
  },
  greenText: {
    color: 'rgba(41, 207, 66, 1)',
  },
  redText: {
    color: 'red',
  },
  debitTransaction: {
    fontWeight: 'bold',
  },
  saveableAddress: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));

const SECTION = Object.freeze({
  TRANSACTIONS: 0,
});

const AccountSection = ({
  transactions,
  selectedAccount,
  contacts,
  accounts,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState(SECTION.TRANSACTIONS);

  const [transactionsOrder, setTransactionsOrder] = useState('asc');
  const [transactionsOrderBy, setTransactionsOrderBy] = useState('time');
  const [selectedAddress, setSelectedAddress] = useState('');

  const convertToAlias = (address) => {
    let existingAccount = accounts
      .concat(contacts)
      .find((account) => account.address.toLowerCase() == address);
    return existingAccount;
  };

  const handleAddressClick = (address) => {
    if (!convertToAlias(address)) {
      setSelectedAddress(address);
    }
  };

  const onCloseContactDialog = () => {
    setSelectedAddress('');
  };
  const transactionTable = [
    {
      id: 1,
      name: t('accounts.table_col_status'),
      align: 'left',
      field: 'status',
    },
    {
      id: 2,
      name: t('accounts.table_col_time'),
      align: 'left',
      field: 'timestamp',
    },
    {
      id: 3,
      name: t('accounts.table_col_sender'),
      align: 'left',
      field: 'sender',
    },
    {
      id: 4,
      name: t('accounts.table_col_receiver'),
      align: 'left',
      field: 'receiver',
    },
    {
      id: 5,
      name: t('accounts.table_col_amount'),
      align: 'right',
      field: 'amount',
    },
  ];

  return (
    <div>
      <div>
        <Button
          className={`${classes.sectionButton} ${
            selectedSection === SECTION.TRANSACTIONS
              ? classes.selectedButton
              : classes.defaultButton
          }`}
          disableElevation
          variant='contained'
          onClick={() => setSelectedSection(SECTION.TRANSACTIONS)}
        >
          {t('accounts.transactions_label')}{' '}
          <PetraChip label={selectedAccount?.transactions?.length} />
        </Button>
      </div>

      {selectedSection === SECTION.TRANSACTIONS && (
        <div className={classes.transactionsHolder}>
          <TableContainer
            component={Paper}
            className={classes.transactionsContainer}
            elevation={0}
          >
            <Table size='small'>
              <TableHead>
                <TableRow>
                  {transactionTable.map((column) => (
                    <TableCell
                      key={column.id}
                      sortDirection={
                        transactionsOrderBy === column.field
                          ? transactionsOrder
                          : false
                      }
                      align={column.align}
                      className={classes.transactionsHeader}
                    >
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.transactionsBody}>
                {selectedAccount?.transactions &&
                  selectedAccount?.transactions?.length > 0 &&
                  selectedAccount?.transactions.map((transaction) => (
                    <TableRow className={classes.transactionsRow}>
                      <TableCell className={classes.transactionsItem}>
                        {transaction.txreceipt_status === '1'
                          ? t('accounts.success_label')
                          : transaction.txreceipt_status === '-1'
                          ? t('accounts.pending_label')
                          : transaction.txreceipt_status === '-999.999'
                          ? t('accounts.processing_label')
                          : t('accounts.failed_label')}
                      </TableCell>
                      <TableCell className={classes.transactionsItem}>
                        {transaction.timeStamp
                          ? `${new Date(
                              parseInt(transaction.timeStamp * 1000)
                            ).toLocaleDateString()} ${new Date(
                              parseInt(transaction.timeStamp * 1000)
                            ).toLocaleTimeString()}`
                          : ''}
                      </TableCell>

                      {convertToAlias(transaction.from.toLowerCase())?.name !==
                      undefined ? (
                        <TableCell
                          className={classes.transactionsItem}
                          onClick={() =>
                            handleAddressClick(transaction.from.toLowerCase())
                          }
                        >
                          {convertToAlias(transaction.from.toLowerCase()).name}
                        </TableCell>
                      ) : (
                        <TableCell
                          className={`${classes.transactionsItem} ${classes.saveableAddress}`}
                          onClick={() =>
                            handleAddressClick(transaction.from.toLowerCase())
                          }
                        >
                          {transaction.from.toLowerCase()}
                        </TableCell>
                      )}

                      {convertToAlias(transaction.to.toLowerCase())?.name !==
                      undefined ? (
                        <TableCell
                          className={classes.transactionsItem}
                          onClick={() =>
                            handleAddressClick(transaction.to.toLowerCase())
                          }
                        >
                          {convertToAlias(transaction.to.toLowerCase()).name}
                        </TableCell>
                      ) : (
                        <TableCell
                          className={`${classes.transactionsItem} ${classes.saveableAddress}`}
                          onClick={() =>
                            handleAddressClick(transaction.to.toLowerCase())
                          }
                        >
                          {transaction.to.toLowerCase()}
                        </TableCell>
                      )}
                      <TableCell
                        align='right'
                        className={`${classes.transactionsItem} ${
                          transaction.from.toLowerCase() ===
                          selectedAccount.address.toLowerCase()
                            ? null
                            : classes.debitTransaction
                        }`}
                      >
                        {`${
                          transaction.from.toLowerCase() ===
                          selectedAccount.address.toLowerCase()
                            ? '-'
                            : '+'
                        } ${convertWeiToPetra(transaction.value.toString())}`}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <ContactCreateDialog
        onCloseContactDialog={onCloseContactDialog}
        address={selectedAddress}
      ></ContactCreateDialog>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  contacts: selectContactsList(state),
  accounts: selectAccountList(state),
  transactions: selectTransactionsByAddress(props.address)(state),
});

export default connect(mapStateToProps)(AccountSection);
