import { createSelector } from 'reselect';

const selectSettings = (state) => state.settings;

export const selectTheme = createSelector(
  [selectSettings],
  (settings) => settings.theme
);

export const selectCurrency = createSelector(
  [selectSettings],
  (settings) => settings.currency
);

export const selectLanguage = createSelector(
  [selectSettings],
  (settings) => settings.language
);

export const selectBlockchains = createSelector(
  [selectSettings],
  (settings) => settings.blockchains
);
