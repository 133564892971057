import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core';

const PetraTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    width: 110,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    border: '1px solid rgba(188,188,188,0.2)',
    borderRadius: 3,

    '& svg': {
      fill: '#AFAFAF',
    },

    '&:hover': {
      opacity: 1,
      backgroundColor: 'transparent',
      fontWeight: theme.typography.fontWeightRegular,

      '& svg': {
        fill: theme.palette.action2.main,
      },
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: 'rgba(124, 158, 175,0.15)',
      border: 0,

      '& svg': {
        fill: theme.palette.action2.main,
      },
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default PetraTab;
