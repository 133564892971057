import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  accountAction: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: 30,
    marginLeft: 15,
    marginTop: 15,
    marginBottom: 15,
    fontSize: 14,
    color: theme.palette.tabButtonIcon.selected,
  },
  accountActionButton: {
    borderRadius: 11,
    width: 'fit-content',
    padding: '5px 7px',
    cursor: 'pointer',
    marginBottom: 20,
  },
}));

const AccountActionButton = ({ index, value, onSelected, label, Icon }) => {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <div className={classes.accountAction}>
      <div
        className={classes.accountActionButton}
        style={{
          backgroundColor:
            value === index
              ? 'transparent'
              : theme.palette.tabButtonIcon['selected' + index],
          border: '3.5px solid ' + theme.palette.tabButtonIcon['selected' + index]
        }}
        onClick={() => onSelected(index)}
      >
        <Icon
          fill={
            value === index
              ? theme.palette.tabButtonIcon['selected' + index]
              : theme.palette.tabButtonIcon.default
          }
          width={25}
          height={25}
        />
      </div>
      {label}
    </div>
  );
};

export default AccountActionButton;
