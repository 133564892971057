import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import * as BlockchainService from '../../../services/BlockchainService';
import PetraInput from '../../reusable/PetraInput';
import InfoAlert from '../../reusable/InfoAlert';
import PetraCheckbox from '../../reusable/PetraCheckbox';
import {
  RestoreWalletIcon,
  KeystoreIcon,
  PrivateKeyIcon,
  UploadIcon,
  ViewOffIcon,
  ViewOnIcon,
} from '../../reusable/icons';
import BasicDialog from '../../reusable/BasicDialog';
import PetraTabs from '../../reusable/PetraTabs';
import PetraTab from '../../reusable/PetraTab';
import PetraButton from '../../reusable/PetraButton';
import PetraTextField from '../../reusable/PetraTextField';
import PetraInputLabel from '../../reusable/PetraLabel';
import { setLoading } from '../../../redux/slices/generalSlice';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { LOADING_STATUS } from '../../../utils/constants';
import {
  addAccount,
  updateAccountBalance,
  updateNewAccountPassword,
} from '../../../redux/slices/accountsSlice';
import {
  selectAccountList,
  newAccountPassword,
} from '../../../redux/selectors/accountsSelector';
import { getAccountBalance } from '../../../services/BlockchainService';
import { browserCanSave } from '../../../utils/BrowserCheck';

const useStyle = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  label: {
    color: theme.palette.extension1.main,
  },
  passicon: {
    fill: theme.palette.action2.main,
    stroke: theme.palette.action2.main,
  },
  fileName: {
    fontSize: 12,
    textAlign: 'center',
    color: 'green',
  },
  invalidFileType: { color: 'red' },
  dialogButtonGroup: {
    textAlign: 'center',
    marginTop: 20,
    paddingBottom: 20,
  },
  uploadKeystoreButton: {
    whiteSpace: 'nowrap',
  },
  keystoreCombinationError: {
    whiteSpace: 'nowrap',
    paddingLeft: 0,
  },
}));

const RestoreAccount = ({
  setLoading,
  accounts,
  addAccount,
  updateAccountPassword,
  accountPassword,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [privateKey, setPrivateKey] = useState('');
  const [keyStore, setKeyStore] = useState({});
  const [fileName, setFileName] = useState('');
  const [creating, setCreating] = useState(false);
  const [isValidFile, setIsValidFile] = useState(false);
  const [validConfirm, setValidConfirm] = useState(true);
  const [validConfirmValue, setValidConfirmValue] = useState('');
  const [validPass, setValidPass] = useState(true);
  const [validKey, setValidKey] = useState(true);
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [restoreWithKeystoreError, setRestoreWithKeystoreError] =
    useState(false);
  const [restoreWithPrivateKeyError, setRestoreWithPrivateKeyError] =
    useState(false);
  const [dialogDimensions, setDialogDimensions] = useState({});
  let isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  let isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  let isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  let isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  useEffect(() => {
    if (isExtraLargeScreen) {
      setDialogDimensions({ height: '75vh', boxHeight: '65vh' });
      return;
    }
    if (isLargeScreen) {
      setDialogDimensions({ height: '85vh', boxHeight: '70vh' });
      return;
    }
    if (isMediumScreen) {
      setDialogDimensions({ height: '65vh', boxHeight: '45vh' });
      return;
    }
    if (isMobile) {
      setDialogDimensions({ height: '85vh', boxHeight: '65vh' });
      return;
    }
  }, [isMobile, isLargeScreen, isExtraLargeScreen]);

  const handleFileChange = async (event) => {
    const reader = new FileReader();
    let _isValidFile = event.target.files[0].type === '' ? true : false;
    setIsValidFile(_isValidFile);
    reader.onload = async (event) => {
      const result = event.target.result;
      if (_isValidFile) {
        setFileName(t('dialogs.restore_account.file_name'));
        setKeyStore(JSON.parse(result));
      } else {
        setKeyStore({});
        setFileName(t('dialogs.restore_account.file_invalid'));
      }
    };
    reader.readAsText(event.target.files[0]);
  };

  const handleUnlockAccount = () => {
    setCreating(true);
    switch (selectedTab) {
      case 0: {
        if (!keyStore || !password) {
          setCreating(false);
        } else {
          getAccountFromKeystore();
        }
        break;
      }
      case 1: {
        if (!privateKey && !validConfirm) {
          setCreating(false);
        } else {
          getAccountFromPrivateKey();
        }
        break;
      }
      default: {
        setCreating(false);
        break;
      }
    }
  };

  const getAccountFromKeystore = async () => {
    const account = await BlockchainService.getAccountFromKeystore(
      keyStore,
      password
    );
    if (account) {
      const existingAccount = accounts
        ? accounts.find((item) => item.address === account.address)
        : null;
      if (existingAccount) {
        setLoading({
          name: 'account/restore/accountExist',
          status: LOADING_STATUS.FAILURE,
          message: t('dialogs.restore_account.account_exists'),
        });
      } else {
        const accountInfo = {
          name: `My Account ${accounts.length + 1}`,
          ...account,
          pta: await getAccountBalance(account),
          order: accounts.length + 1,
          keystore: keyStore,
        };
        addAccount(accountInfo);
        setLoading({
          name: 'account/restore/success',
          status: LOADING_STATUS.SUCCESS,
          message: t('dialogs.restore_account.account_success'),
        });
        setOpen(false);
      }
    } else {
      setRestoreWithKeystoreError(true);
    }

    setCreating(false);
  };

  const getAccountFromPrivateKey = async () => {
    setCreating(true);
    const account = await BlockchainService.getAccountFromPrivateKey(
      privateKey,
      accountPassword
    );
    if (account?.address) {
      const existingAccount = accounts
        ? accounts.find((item) => item.address === account.address)
        : null;
      if (existingAccount) {
        setLoading({
          name: 'account/restore/accountExist',
          status: LOADING_STATUS.FAILURE,
          message: t('dialogs.restore_account.account_exists'),
        });
      } else {
        const accountInfo = {
          name: `My Account ${accounts.length + 1}`,
          address: account.address,
          pta: await getAccountBalance(account),
          order: accounts.length + 1,
          keystore: account.keystore,
        };
        addAccount(accountInfo);
        setLoading({
          name: 'account/restore/success',
          status: LOADING_STATUS.SUCCESS,
          message: t('dialogs.restore_account.account_success'),
        });
        if (browserCanSave()) {
          BlockchainService.downloadKeystore(account.keystore);
        }
        setOpen(false);
      }
    } else {
      setRestoreWithPrivateKeyError(true);
    }

    setCreating(false);
  };

  const handlePasswordChange = (name, value) => {
    // validate field
    var passw =
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,200}$/;
    setValidPass(value ? Boolean(value.match(passw)) : true);
    updateAccountPassword(value);
    if (validConfirmValue) {
      setValidConfirm(value === validConfirmValue);
    }
  };

  const handlePrivateKeyChange = (event) => {
    if (event.target.value.length === 64) {
      setValidKey(true);
    } else {
      setValidKey(false);
    }
    setPrivateKey(event.target.value);
  };

  const handlePasswordConfirmChange = (name, value) => {
    setValidConfirm(value === accountPassword);
    setValidConfirmValue(value);
  };

  const handleAgreeCheck = (event) => {
    setAgreeCheck(event.target.checked);
  };

  const handleOpen = () => {
    // reset states
    setSelectedTab(0);
    setPassword('');
    setPrivateKey('');
    setKeyStore({});
    setFileName('');
    setCreating(false);
    setValidConfirm(true);
    setValidConfirmValue('');
    setValidPass(true);
    setAgreeCheck(false);
    setValidKey(true);
    setOpen(true);
    setRestoreWithKeystoreError(false);
    setRestoreWithPrivateKeyError(false);
  };

  const handleChange = (event, newValue) => {
    setPassword('');
    setPrivateKey('');
    setKeyStore({});
    setFileName('');
    setCreating(false);
    setValidConfirm(true);
    setValidConfirmValue('');
    setValidPass(true);
    setAgreeCheck(false);
    setOpen(true);
    setSelectedTab(newValue);
    setRestoreWithKeystoreError(false);
    setRestoreWithPrivateKeyError(false);
  };

  return (
    <>
      <Box onClick={handleOpen} className={classes.icon}>
        <RestoreWalletIcon />
        <Box mt={2}>
          <Typography variant='caption'>{t('home.restore_account')}</Typography>
        </Box>
      </Box>

      <BasicDialog
        open={open}
        onClose={() => setOpen(false)}
        title={t('dialogs.restore_account.title')}
        height={selectedTab === 1 ? dialogDimensions.height : null}
        boxHeight={selectedTab === 1 ? dialogDimensions.boxHeight : null}
      >
        <PetraTabs centered value={selectedTab} onChange={handleChange}>
          <PetraTab
            label={t('dialogs.restore_account.keystore_tab')}
            icon={<KeystoreIcon />}
          />
          <PetraTab
            label={t('dialogs.restore_account.private_tab')}
            icon={<PrivateKeyIcon />}
          />
        </PetraTabs>

        <Box mt={4} height={180}>
          {selectedTab === 0 && (
            <div>
              <PetraButton
                className={classes.uploadKeystoreButton}
                variant='outlined'
                fullWidth
                startIcon={<UploadIcon className={classes.passicon} />}
                component='label'
              >
                {t('dialogs.restore_account.upload_key_button')}
                <input type='file' onChange={handleFileChange} hidden />
              </PetraButton>
              <Box mt={1}>
                <Typography
                  className={`${classes.fileName} ${
                    !isValidFile ? classes.invalidFileType : null
                  }`}
                >
                  {fileName}
                </Typography>
              </Box>

              <Box mt={2}>
                <PetraInputLabel htmlFor='account-password'>
                  {t('dialogs.restore_account.password_label')}
                </PetraInputLabel>
                <PetraTextField
                  id='account-password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                          disableRipple
                          size='small'
                        >
                          {showPassword ? (
                            <ViewOnIcon className={classes.passicon} />
                          ) : (
                            <ViewOffIcon className={classes.passicon} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box pl={26} className={classes.keystoreCombinationError}>
                <InfoAlert
                  style={{
                    display: restoreWithKeystoreError ? '' : 'none',
                  }}
                  type='error'
                  text={t('dialogs.restore_account.keystore_combination_error')}
                />
              </Box>
            </div>
          )}

          {selectedTab === 1 && (
            <div>
              <Box mt={3} mb={3} className={classes.restoreDialog}>
                <PetraInput
                  caption={t('dialogs.restore_account.private_key_label')}
                  id='privateKey'
                  isvalid={validKey}
                  value={privateKey}
                  onChange={handlePrivateKeyChange}
                  invalidtext={t('dialogs.create_account.private_key_error')}
                />
                <Box pl={26} mt={1}>
                  <InfoAlert
                    style={{
                      display: restoreWithPrivateKeyError ? '' : 'none',
                    }}
                    type='error'
                    text={t(
                      'dialogs.restore_account.private_key_combination_error'
                    )}
                  />
                </Box>
                <div>
                  <h3>{t('dialogs.create_account.step1_title')}</h3>
                  <PetraInput
                    caption={t('dialogs.create_account.password_caption')}
                    id='password'
                    ispassword='true'
                    isvalid={validPass}
                    onBlurValue={handlePasswordChange}
                    invalidtext={t('dialogs.create_account.password_error')}
                  />
                  <InfoAlert
                    style={{
                      marginBottom: 10,
                      display: validPass ? '' : 'none',
                    }}
                    type='info'
                    text={t('dialogs.create_account.password_error')}
                  />
                  <PetraInput
                    caption={t('dialogs.create_account.repassword_caption')}
                    id='pass_confirm'
                    isvalid={validConfirm}
                    ispassword='true'
                    invalidtext={t('dialogs.create_account.repassword_error')}
                    onChangeValue={handlePasswordConfirmChange}
                  />
                  <PetraCheckbox
                    style={{ marginTop: 10 }}
                    label={t('dialogs.create_account.checkbox_label')}
                    onChange={handleAgreeCheck}
                  />
                </div>
                <Box
                  display='flex'
                  justifyContent='center'
                  mt={'5vh'}
                  position='relative'
                >
                  <PetraButton
                    onClick={handleUnlockAccount}
                    disabled={
                      !accountPassword ||
                      !validConfirmValue ||
                      !validPass ||
                      !validConfirm ||
                      !agreeCheck ||
                      !validKey ||
                      creating
                    }
                  >
                    {t('dialogs.create_account.restore_button_key')}
                  </PetraButton>
                  <Box
                    hidden={!creating}
                    style={{ position: 'absolute', top: 14 }}
                  >
                    <CircularProgress size={25} />
                  </Box>
                </Box>
              </Box>
            </div>
          )}
        </Box>
        {selectedTab === 0 ? (
          <Box
            display='flex'
            justifyContent='center'
            mt={4}
            position='relative'
          >
            <PetraButton
              onClick={handleUnlockAccount}
              disabled={creating || !isValidFile || !password}
            >
              {t('dialogs.restore_account.unlock_button')}
            </PetraButton>
            <Box hidden={!creating} style={{ position: 'absolute', top: 14 }}>
              <CircularProgress size={25} />
            </Box>
          </Box>
        ) : null}
      </BasicDialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
  accountPassword: newAccountPassword,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (data) => dispatch(setLoading(data)),
  addAccount: (data) => dispatch(addAccount(data)),
  updateAccountPassword: (value) => dispatch(updateNewAccountPassword(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestoreAccount);
