import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import {
  SwitchSun,
  SwitchMoon,
} from '../reusable/icons';

import { setTheme, setCurrency, setLanguage } from '../../redux/slices/settingsSlice';
import {
  selectTheme,
  selectCurrency,
  selectLanguage,
} from '../../redux/selectors/settingsSelector';
import PetraCard from '../reusable/PetraCard';

const currencies = [
  {symbol:"$",value:"usd"},
  {symbol:"€",value:"eur"},
  {symbol:"¥",value:"cny"},
  {symbol:"¥",value:"jpy"},
  {symbol:"£",value:"gbp"}
]

const useStyles = makeStyles((theme) => ({
  label: {
    marginTop: 20,
    marginBottom: 10,
  },
  settingsContainer: {
    padding: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      paddingBottom: 100,
    },
  },
  switchRoot: {
    height: '45px',
    width: '70px',
  },
  switchTrack: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.typography.subtitle1.color,
    borderRadius: '30px'
  },
  switchIconCircle: {
    background: 'white',
    borderRadius: '30px',
    width: 22,
    height: 21,
    marginTop: 4,
    marginLeft: 4,
  },
  switchIcon: {
    top: 15,
    position: 'absolute',
    left: 13
  }
}));

const Settings = ({
  theme,
  setTheme,
  currency,
  setCurrency,
  language,
  setLanguage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    updateIosStatusBar(theme === 'light');
  };

  const updateIosStatusBar = (darkMode) => {
    var appleThemeColor = document.querySelector("meta[name=apple-mobile-web-app-status-bar-style]");
    if(darkMode) appleThemeColor.setAttribute("content", "black");
    else appleThemeColor.setAttribute("content", "default");
  }

  const handleCurrencyChange = (value) =>{
    var currency = currencies.find(x=>x.value == value);
    setCurrency(currency);
  }

  function SwitchThumb(props) {
    return (
      <Box className={classes.switchIconCircle}>
        {props.isChecked ? <SwitchMoon className={classes.switchIcon} /> : <SwitchSun className={classes.switchIcon} />}
      </Box>
    );
  }

  return (
    <div className={classes.settingsContainer}>
      <Typography variant='h5'>{t('settings.title')}</Typography>

      <PetraCard>
        <Grid container justify='center'>
          <Grid item xs={11} md={8}>
            <InputLabel id='currency-label' className={classes.label}>
              {t('settings.currency_label')}
            </InputLabel>
            <Select
              fullWidth
              native
              variant='outlined'
              id='currency'
              value={currency?.value}
              onChange={(e) => handleCurrencyChange(e.target.value)}
            >
              <option value='usd'>{t('settings.currency_usd')}</option>
              <option value='cny'>{t('settings.currency_yuan')}</option>
              <option value='eur'>{t('settings.currency_euro')}</option>
              <option value='jpy'>{t('settings.currency_yen')}</option>
              <option value='gbp'>{t('settings.currency_gbp')}</option>
            </Select>
            <InputLabel id='language-label' className={classes.label}>
              {t('settings.language_label')}
            </InputLabel>
            <Select
              fullWidth
              native
              variant='outlined'
              id='currency'
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value='en'>{t('settings.language_english')}</option>
              <option value='ch'>{t('settings.language_chinese')}</option>
            </Select>

            <FormGroup className={classes.label}>
              <FormControlLabel
                control={
                  <Switch
                    icon={<SwitchThumb isChecked={false} />}
                    checkedIcon={<SwitchThumb isChecked={true} />}
                    color='primary'
                    onChange={toggleTheme}
                    checked={theme === 'dark'}
                    classes={{
                      root: classes.switchRoot,
                      track: classes.switchTrack
                    }}
                  />
                }
                label={theme === 'dark' ? t('settings.dark_mode_label') : t('settings.light_mode_label')}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </PetraCard>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  theme: selectTheme,
  currency: selectCurrency,
  language: selectLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setTheme: (theme) => dispatch(setTheme(theme)),
  setCurrency: (currency) => dispatch(setCurrency(currency)),
  setLanguage: (language) => dispatch(setLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
