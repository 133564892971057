import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core';

const PetraInputLabel = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 700,
  },
}))(InputLabel);

export default PetraInputLabel;
