import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const PetraChip = withStyles((theme) => ({
  root: {
    height: 15,
    marginLeft: 5,
    backgroundColor: 'rgba(0,0,0,0.15)',

    '& > span': {
      fontSize: 10,
      fontWeight: 700,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}))((props) => <Chip {...props} />);

export default PetraChip;
