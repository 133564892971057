import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Box from '@material-ui/core/Box';
import { makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as ConnectWalletIcon } from '../../reusable/icons/img/create-wallet.svg';
import BasicDialog from '../../reusable/BasicDialog';
import PetraInput from '../../reusable/PetraInput';
import PetraButton from '../../reusable/PetraButton';
import PetraCheckbox from '../../reusable/PetraCheckbox';
import InfoAlert from '../../reusable/InfoAlert';

import * as BlockchainService from '../../../services/BlockchainService';
import {
  updateNewAccountPassword,
  resetNewAccount,
  updateNewAccountPhrase,
  addAccount,
} from '../../../redux/slices/accountsSlice';
import {
  newAccountPassword,
  selectAccountList,
} from '../../../redux/selectors/accountsSelector';
import { setLoading } from '../../../redux/slices/generalSlice';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LOADING_STATUS } from '../../../utils/constants';

const useStyle = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  label: {
    color: theme.palette.extension1.main,
  },
  dialogButtonGroup: {
    textAlign: 'center',
    marginTop: 20,
  },
  '@keyframes bounce': {
    '0%': { transform: 'translateY(0)' },
    '20%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(0)' },
    '80%': { transform: 'translateY(0)' },
    '100%': { transform: 'translateY(0)' },
    '40%': { transform: 'translateY(-15px)' },
    '60%': { transform: 'translateY(-5px)' },
  },
  iconBounce: {
    WebkitBorderRadius: '50%',
    MozBorderRadius: '50%',
    MsBorderRadius: '50%',
    borderRadius: '50%',
    animation: '$bounce 2s infinite',
  },
  successDetails: {
    [theme.breakpoints.down('sm')]: {
      wordWrap: 'break-word',
    },
  },
}));

const CreateWalletButton = ({
  accounts,
  accountPassword,
  updateAccountPassword,
  updateAccountPhrase,
  resetAccount,
  addAccount,
  setLoading,
}) => {
  const classes = useStyle();
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [accountStep, setAccountStep] = useState(1);
  const [validPass, setValidPass] = useState(true);
  const [validConfirm, setValidConfirm] = useState(true);
  const [validConfirmValue, setValidConfirmValue] = useState('');
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [accountInformation, setAccountInformation] = useState({});
  const [creating, setCreating] = useState(false);

  const handleClickOpen = () => {
    resetAccount(true);
    setValidConfirm(true);
    setAccountStep(1);
    setValidPass(true);
    setValidConfirmValue(true);
    setValidConfirmValue('');
    setAgreeCheck(false);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleAccessAccount = () => {
    history.push(`/account/${accountInformation.address}`);
  };

  const handlePasswordChange = (name, value) => {
    // validate field
    var passw =
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,200}$/;
    setValidPass(value ? Boolean(value.match(passw)) : true);
    updateAccountPassword(value);
    if (validConfirmValue) {
      setValidConfirm(value === validConfirmValue);
    }
  };

  const handleAccountCreateSubmit = async () => {
    setCreating(true);
    let account = await BlockchainService.createNewAccount(accountPassword);
    if (account) {
      const accountInfo = {
        name:
          t('dialogs.create_account.account_name') + ` ${accounts.length + 1}`,
        ...account,
        pta: 0,
        order: accounts.length + 1,
        keystore: account.keystore,
      };
      delete accountInfo.privateKey;
      addAccount(accountInfo);
      setAccountInformation(account);
      setLoading({
        name: 'account/create',
        status: LOADING_STATUS.SUCCESS,
        message: t('dialogs.create_account.account_created'),
      });
      setCreating(false);
      nextStep();
    } else {
      setLoading({
        name: 'account/create',
        status: LOADING_STATUS.FAILURE,
        message: t('dialogs.create_account.error_account_created'),
      });
    }
  };

  const handlePasswordConfirmChange = (name, value) => {
    setValidConfirm(value === accountPassword);
    setValidConfirmValue(value);
  };

  const handleAgreeCheck = (event) => {
    setAgreeCheck(event.target.checked);
  };

  const nextStep = () => {
    setAccountStep(accountStep + 1);
  };

  return (
    <>
      <Box
        onClick={handleClickOpen}
        className={`${classes.icon} ${
          accounts.length === 0 ? classes.iconBounce : null
        }`}
      >
        <ConnectWalletIcon />
        <Box mt={2}>
          <Typography variant='caption'>{t('home.create_account')}</Typography>
        </Box>
      </Box>

      <BasicDialog
        open={open}
        onClose={handleClose}
        title={t('dialogs.create_account.title')}
      >
        {accountStep === 1 && (
          <div>
            <h3>{t('dialogs.create_account.step1_title')}</h3>
            <PetraInput
              caption={t('dialogs.create_account.password_caption')}
              id='password'
              ispassword='true'
              isvalid={validPass}
              onBlurValue={handlePasswordChange}
              invalidtext={t('dialogs.create_account.password_error')}
            />
            <InfoAlert
              style={{ marginBottom: 10, display: validPass ? '' : 'none' }}
              type='info'
              text={t('dialogs.create_account.password_error')}
            />
            <PetraInput
              caption={t('dialogs.create_account.repassword_caption')}
              id='pass_confirm'
              isvalid={validConfirm}
              ispassword='true'
              invalidtext={t('dialogs.create_account.repassword_error')}
              onChangeValue={handlePasswordConfirmChange}
            />
            <PetraCheckbox
              style={{ marginTop: 10 }}
              label={t('dialogs.create_account.checkbox_label')}
              onChange={handleAgreeCheck}
            />
            <div className={classes.dialogButtonGroup}>
              <PetraButton
                disabled={
                  !accountPassword ||
                  !validConfirmValue ||
                  !validPass ||
                  !validConfirm ||
                  !agreeCheck ||
                  creating
                }
                onClick={handleAccountCreateSubmit}
                startIcon={
                  creating && (
                    <CircularProgress size={20} style={{ marginRight: 10 }} />
                  )
                }
              >
                {t('dialogs.create_account.step1_download_button')}
              </PetraButton>
            </div>
          </div>
        )}

        {accountStep === 2 && (
          <div>
            <h3 style={{ textAlign: 'center' }}>
              {t('dialogs.create_account.step2_title')}
            </h3>
            <h5 style={{ textAlign: 'center' }}>
              {t('dialogs.create_account.step2_subtitle')}
            </h5>

            <p
              className={classes.successDetails}
              style={{ textAlign: 'center', lineHeight: 1.5 }}
            >
              <strong>
                {t('dialogs.create_account.step2_address_label')}:
              </strong>
              <br /> {accountInformation?.address}
            </p>
            <p
              className={classes.successDetails}
              style={{ textAlign: 'center', lineHeight: 1.5 }}
            >
              <strong>{t('dialogs.create_account.step2_key_label')}:</strong>
              <br />
              {accountInformation?.privateKey}
            </p>

            <div className={classes.dialogButtonGroup}>
              <PetraButton variant='outlined' onClick={handleAccessAccount}>
                {t('dialogs.create_account.step2_access_button')}
              </PetraButton>
            </div>
          </div>
        )}
      </BasicDialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  accountPassword: newAccountPassword,
  accounts: selectAccountList,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountPassword: (value) => dispatch(updateNewAccountPassword(value)),
  updateAccountPhrase: (value) => dispatch(updateNewAccountPhrase(value)),
  resetAccount: () => dispatch(resetNewAccount(true)),
  addAccount: (account) => dispatch(addAccount(account)),
  setLoading: (data) => dispatch(setLoading(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateWalletButton);
