import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Menu from './components/Menu/Menu';
import Home from './components/Home/Home';
import Settings from './components/Settings/Settings';
import ContactPage from './components/Contacts/ContactPage';
import { makeStyles } from '@material-ui/core/styles';
import AccountsPage from './components/Accounts/AccountsPage';
import AccountDetail from './components/Accounts/AccountDetail';
import General from './components/General';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-MKF4C9G'
}
TagManager.initialize(tagManagerArgs);

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    background: theme.palette.background.defaultGradient,
    // padding: theme.spacing(3),
  },
}));

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Router>
        <Menu />
        <General />
        <main className={classes.content}>
          <Switch>
            <Route exact path='/account' component={AccountsPage} />
            <Route path='/account/:address' component={AccountDetail} />
            <Route path='/settings'>
              <Settings />
            </Route>
            <Route path='/contacts' component={ContactPage} />
            <Route path='/'>
              <Home />
            </Route>
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
