import { React, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { selectAccountList } from '../../redux/selectors/accountsSelector';
import Account from './Account';
import {
  convertWeiToPetra,
  getAccountBalance,
} from '../../services/BlockchainService';
import { updateAccountBalance } from '../../redux/slices/accountsSlice';

const AccountList = ({
  accounts,
  type = 1,
  onSelect,
  updateAccountBalance,
  searchValue = '',
  petraPrice,
  selectedAccount,
}) => {
  useEffect(() => {
    accounts.map(async (account) =>
      updateAccountBalance({
        address: account.address,
        balance: await getAccountBalance(account),
      })
    );
  }, accounts);
  return (
    <>
      {accounts
        .filter((account) =>
          searchValue === ''
            ? true
            : account.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
        )
        .filter((account) => account.address !== selectedAccount?.address)
        .map((account, index) => {
          return (
            <Account
              key={index}
              name={account.name}
              address={account.address}
              length={accounts.length}
              order={account.order}
              pta={convertWeiToPetra(
                account.pta ? account.pta.toString() : '0'
              )}
              rawPta={account.pta}
              dollar={
                convertWeiToPetra(account.pta ? account.pta.toString() : '0') *
                petraPrice
              }
              type={type}
              onSelect={onSelect}
              account={account}
            />
          );
        })}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  accounts: selectAccountList,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountBalance: (value) => dispatch(updateAccountBalance(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
