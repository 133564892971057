import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // height: 32,
  },
  [theme.breakpoints.down('sm')]: {
    titleContainer: {
      height: 'auto',
      flexDirection: 'column-reverse',

      '& > h5': {
        alignSelf: 'flex-start',
      },

      '& > div': {
        alignSelf: 'flex-end',
        marginBottom: 10,
      },
    },
  },
}));

const AccountTitle = ({ title, components }) => {
  const classes = useStyle();
  return (
    <div className={classes.titleContainer}>
      <Typography variant='h5'>{title}</Typography>
      <div>{components}</div>
    </div>
  );
};

export default AccountTitle;
