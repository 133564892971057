import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const PetraTextField = withStyles((theme) => ({
  root: {
    marginTop: 4,
    marginBottom: 18,

    '& .MuiOutlinedInput-root': {
      borderRadius: 12,
      backgroundColor: theme.palette.type === 'light' ? '#E8E8E9' : '#323A41',

      '& fieldset': {
        borderColor: '#3D4851',
      },
      '& input': {
        fontSize: 14,
        color: theme.palette.type === 'light' ? '#000000' : '#FFFFFF',
        padding: '15px 12px',
        '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active':
          theme.palette.type === 'light'
            ? {
                '-webkit-box-shadow': '0 0 0 60px #E8E8E9 inset !important',
                'background-color': '#E8E8E9 !important',
                color: '#000 !important',
                'background-clip': 'content-box !important',
              }
            : {
                '-webkit-box-shadow': '0 0 0 60px #323A41 inset !important',
                'background-color': '#323A41 !important',
                color: '#FFF !important',
                'background-clip': 'content-box !important',
              },
      },
      '& textarea': {
        fontSize: 14,
        color: theme.palette.type === 'light' ? '#000000' : '#FFFFFF',
        padding: '10px 12px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.action2.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.action2.main,
      },
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
  },
}))((props) => (
  <TextField
    variant='outlined'
    fullWidth
    {...props}
    onWheel={(event) => event.target.blur()}
    autoComplete='new-password'
  />
));

export default PetraTextField;
