import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import InfoAlert from './reusable/InfoAlert';
import PetraInput from './reusable/PetraInput';
import PetraButton from './reusable/PetraButton';
import {
  updateAccountTransactions,
  updateTransaction,
  addPendingTransaction,
  cancelProcessingTransactions,
} from '../redux/slices/accountsSlice';
import { selectTheme } from '../redux/selectors/settingsSelector';
import { selectAccountList } from '../redux/selectors/accountsSelector';
import { selectContactsList } from '../redux/selectors/contactsSelector';
import { selectLoading } from '../redux/selectors/generalSelector';
import { resetLoading, setLoading } from '../redux/slices/generalSlice';
import {
  getTransactions,
  sendPetra,
  getAccountFromKeystore,
} from '../services/BlockchainService';
import { LOADING_STATUS } from '../utils/constants';
import BasicDialog from './reusable/BasicDialog';

const useStyles = makeStyles((theme) => ({
  snackbarStyle: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 65,
    },
  },
}));

const General = ({
  theme,
  loading,
  resetLoading,
  setLoading,
  contacts,
  accounts,
  updateAccountTransactions,
  updateTransaction,
  addPendingTransaction,
  cancelProcessingTransactions,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showLoading, setShowLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState({
    type: 'success',
    message: 'Success!',
  });

  const updateLoadingInfo = (obj) => {
    if (!obj.status) {
      setShowLoading(false);
    }
    if (
      obj.status === LOADING_STATUS.FAILURE ||
      obj.status === LOADING_STATUS.SUCCESS
    ) {
      setLoadingInfo({
        type: obj.status === LOADING_STATUS.FAILURE ? 'error' : 'success',
        message: obj.message,
      });
      setShowLoading(true);
    }
  };

  useEffect(() => {
    updateLoadingInfo(loading);
  }, [loading]);

  useEffect(() => {
    updateIosStatusBar(theme === 'dark');
  }, []);

  const updateIosStatusBar = (darkMode) => {
    var appleThemeColor = document.querySelector(
      'meta[name=apple-mobile-web-app-status-bar-style]'
    );
    if (darkMode) appleThemeColor.setAttribute('content', 'black');
    else appleThemeColor.setAttribute('content', 'default');
  };

  const [openPassDialog, setOpenPassDialog] = useState(false);
  const [accountPassword, setAccountPassword] = useState('');
  const [sending, setSending] = useState(false);
  const [validPass, setValidPass] = useState(true);
  const [offlineTransactions, setOfflineTransactions] = useState([]);
  const [currentTransactionIndex, setCurrentTransactionIndex] = useState(-1);
  const [showTransactionDialog, setShowTransactionDialog] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState({});
  const [confirmCancelDialog, setConfirmCancelDialog] = useState(false);

  const startOfflineTransactions = async () => {
    let trans = await AsyncStorage.getItem('@offlineTransactions');
    trans = trans ? JSON.parse(trans) : [];
    setOfflineTransactions(trans);
    if (trans.length) {
      setCurrentTransactionIndex(-1);
      resetDialog();
      setOpenPassDialog(true);
    }
  };

  const resetDialog = () => {
    setAccountPassword('');
    setValidPass(true);
  };

  const handleTransactionSubmit = async () => {
    setSending(true);
    let accountDetails = await getAccountFromKeystore(
      offlineTransactions[currentTransactionIndex].keystore,
      accountPassword
    );
    if (!accountDetails) {
      updateLoadingInfo({
        name: 'transaction/send/invalidPassword',
        status: LOADING_STATUS.FAILURE,
        message: t('accounts.send_opera_incorrect_password'),
      });
      setSending(false);
      return;
    }
    let transactions = await getTransactions({
      address: offlineTransactions[currentTransactionIndex].address,
    });
    if (transactions !== null) {
      updateAccountTransactions({
        address: offlineTransactions[currentTransactionIndex].address,
        transactions,
      });
      let notFailedTransactions = transactions.filter(
        (x) =>
          x.txreceipt_status !== '0' &&
          x.from.toLowerCase() ===
            offlineTransactions[currentTransactionIndex].address.toLowerCase()
      );
      let transaction = await sendPetra(
        offlineTransactions[currentTransactionIndex].address,
        offlineTransactions[currentTransactionIndex].sendTo,
        offlineTransactions[currentTransactionIndex].amount,
        accountDetails.privateKey,
        notFailedTransactions.length
      );
      if (!transaction) {
        transaction = offlineTransactions[currentTransactionIndex].transaction;
        transaction.txreceipt_status = '0';
      }
      transaction.tempIndex =
        offlineTransactions[currentTransactionIndex].transaction.tempIndex;
      updateTransaction({
        address: offlineTransactions[currentTransactionIndex].address,
        replaceTransaction: transaction,
      });
      setTransactionInfo(transaction);
      setShowTransactionDialog(true);
      setSending(false);
      if (currentTransactionIndex + 1 < offlineTransactions.length) {
        setCurrentTransactionIndex(currentTransactionIndex + 1);
        resetDialog();
      } else {
        closeOfflineTransactions();
        setOpenPassDialog(false);
      }
    } else {
      setSending(false);
      updateLoadingInfo({
        name: 'transaction/send/failed',
        status: LOADING_STATUS.FAILURE,
        message: t('accounts.send_opera_failed'),
      });
    }
  };

  useEffect(async () => {
    // Check offline transactions
    window.addEventListener('online', (event) => {
      startOfflineTransactions();
    });
    if (navigator.onLine) {
      startOfflineTransactions();
    }
  }, []);

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    var passw =
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,200}$/;
    setValidPass(value ? Boolean(value.match(passw)) : true);
    setAccountPassword(value);
  };

  const handleOnClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowLoading(false);
    resetLoading();
  };

  const handleClose = () => {
    setOpenPassDialog(false);
    if (currentTransactionIndex >= 0) {
      setConfirmCancelDialog(true);
    }
  };

  const confirmOfflineTransactions = () => {
    setConfirmCancelDialog(false);
    setOpenPassDialog(true);
    setCurrentTransactionIndex(0);
  };

  const closeOfflineTransactions = () => {
    cancelProcessingTransactions({ accounts });
    AsyncStorage.setItem('@offlineTransactions', '');
    setOpenPassDialog(false);
  };

  const convertToAlias = (address) => {
    let existingAccount = accounts
      .concat(contacts)
      .find(
        (account) =>
          account.address.toLowerCase() == String(address).toLowerCase()
      );
    return existingAccount ? existingAccount.name : address;
  };

  const handleTransactionDialogueClose = () => {
    setShowTransactionDialog(false);
  };

  const handleConfirmCancel = () => {
    closeOfflineTransactions();
    setConfirmCancelDialog(false);
  };

  return (
    <>
      <div>
        <Snackbar
          className={classes.snackbarStyle}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={showLoading}
          autoHideDuration={6000}
          onClose={handleOnClose}
        >
          <Alert onClose={handleOnClose} severity={loadingInfo.type}>
            {loadingInfo.message}
          </Alert>
        </Snackbar>
      </div>

      <BasicDialog
        open={openPassDialog}
        onClose={handleClose}
        title={t('dialogs.offline_transaction.title')}
      >
        {offlineTransactions.length && currentTransactionIndex < 0 && (
          <>
            <h3>{t('dialogs.offline_transaction.confirm')}</h3>
            <div style={{ textAlign: 'center', marginTop: 15 }}>
              <PetraButton
                variant='outlined'
                onClick={closeOfflineTransactions}
              >
                {t('dialogs.offline_transaction.confirm_no_button')}
              </PetraButton>
              <Typography component='span' style={{ marginLeft: 15 }}>
                &nbsp;
              </Typography>
              <PetraButton onClick={confirmOfflineTransactions}>
                {t('dialogs.offline_transaction.confirm_button')}
              </PetraButton>
            </div>
          </>
        )}
        {offlineTransactions.length && currentTransactionIndex >= 0 && (
          <>
            <h3>{`Sending ${parseFloat(
              offlineTransactions[currentTransactionIndex]?.amount
            )} to ${convertToAlias(
              offlineTransactions[currentTransactionIndex]?.sendTo
            )}`}</h3>
            <PetraInput
              caption={`${t(
                'dialogs.offline_transaction.password_caption'
              )} ${convertToAlias(
                offlineTransactions[currentTransactionIndex]?.address
              )}`}
              id='password'
              ispassword='true'
              isvalid={validPass}
              value={accountPassword}
              onChange={handlePasswordChange}
              invalidtext={t('dialogs.offline_transaction.password_error')}
            />
            <div style={{ textAlign: 'center', marginTop: 15 }}>
              <PetraButton
                disabled={!accountPassword || !validPass || sending}
                onClick={handleTransactionSubmit}
                startIcon={
                  sending && (
                    <CircularProgress size={20} style={{ marginRight: 10 }} />
                  )
                }
              >
                {t('dialogs.offline_transaction.send_button')}
              </PetraButton>
            </div>
          </>
        )}
      </BasicDialog>

      <BasicDialog
        open={confirmCancelDialog}
        onClose={handleConfirmCancel}
        title={t('dialogs.offline_transaction.title')}
      >
        <>
          <h3>{t('dialogs.offline_transaction.confirm_cancel')}</h3>
          <div style={{ textAlign: 'center', marginTop: 15 }}>
            <PetraButton
              variant='outlined'
              onClick={confirmOfflineTransactions}
            >
              {t('dialogs.offline_transaction.confirm_no_button')}
            </PetraButton>
            <Typography component='span' style={{ marginLeft: 15 }}>
              &nbsp;
            </Typography>
            <PetraButton onClick={handleConfirmCancel}>
              {t('dialogs.offline_transaction.confirm_button')}
            </PetraButton>
          </div>
        </>
      </BasicDialog>

      <BasicDialog
        open={showTransactionDialog}
        onClose={handleTransactionDialogueClose}
        width={550}
      >
        <Box my={4} display='flex' alignItems='center' justifyContent='center'>
          {transactionInfo && transactionInfo.txreceipt_status !== '0' ? (
            <Typography>{t('accounts.send_opera_success')}</Typography>
          ) : navigator.onLine ? (
            <Typography>{t('accounts.send_opera_failed')}</Typography>
          ) : (
            <Typography>{t('accounts.send_opera_offline')}</Typography>
          )}
        </Box>
      </BasicDialog>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  theme: selectTheme,
  contacts: selectContactsList,
  accounts: selectAccountList,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccountTransactions: (value) =>
    dispatch(updateAccountTransactions(value)),
  updateTransaction: (value) => dispatch(updateTransaction(value)),
  cancelProcessingTransactions: (value) =>
    dispatch(cancelProcessingTransactions(value)),
  resetLoading: () => dispatch(resetLoading()),
  addPendingTransaction: (value) => dispatch(addPendingTransaction(value)),
  setLoading: (data) => dispatch(setLoading(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
