import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as HiddenIcon } from './icons/img/hidden-icon.svg';
import { ReactComponent as VisibleIcon } from './icons/img/visible-icon.svg';
import InfoAlert from './InfoAlert';

const useStyles = makeStyles((theme) => ({
  caption: {
    fontSize: 14,
    fontWeight: 700,
  },
  passicon: {
    position: 'absolute',
    right: 0,
    top: 23
  },
  icon: {
    fill: theme.palette.action2.main
  }
}));

const CustomTextField =  withStyles((theme) => ({
    root: props => ({
      marginTop: 4,

      '& .MuiOutlinedInput-root': {
        borderRadius: 12,
        backgroundColor: theme.palette.type === 'light' ? '#E8E8E9' : '#323A41',

        '& fieldset': {
          borderColor: '#3D4851',
        },
        '& input': {
          fontSize: 14,
          color: theme.palette.type === 'light' ? '#000000' : '#FFFFFF',
          padding: '15px 12px',
          '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active': theme.palette.type === 'light' ? {
            '-webkit-box-shadow': '0 0 0 60px #E8E8E9 inset !important',
            'background-color': '#E8E8E9 !important',
            'color': '#000 !important',
            'background-clip': 'content-box !important'
          } : {
            '-webkit-box-shadow': '0 0 0 60px #323A41 inset !important',
            'background-color': '#323A41 !important',
            'color': '#FFF !important',
            'background-clip': 'content-box !important'
          },
        },
        '&:hover fieldset': {
          borderColor: theme.palette.action2.main,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.action2.main,
        },
        '&.Mui-error fieldset': {
          borderColor: theme.palette.action2.error,
        },
      },
    })
  }))(TextField);


const PetraInput = ({ caption, id, value, name, onChangeValue, ispassword, isvalid, invalidtext, onBlurValue, helperText, error, ...otherProps }) => {
  const classes = useStyles();
  const [showValue, setShowValue] = useState(ispassword);

  const handleVisible = () => {
    setShowValue(!showValue);
  };

  return (
    <div {...otherProps}>
      {caption && (
        <Typography className={classes.caption}>{caption}</Typography>
      )}
      <CustomTextField
        id={id}
        value={value}
        name={name}
        error={isvalid === false || error}
        type={ispassword && showValue ? 'password' : 'text'}
        onChange={(e) => {if (onChangeValue){ onChangeValue(id, e.target.value) }}}
        onBlur={(e) => {if (onBlurValue){ onBlurValue(id, e.target.value) }}}
        variant='outlined'
        fullWidth
        autoComplete='new-password'
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={handleVisible}
                edge='end'
                disableRipple
                size='small'
              >
                {showValue ? <VisibleIcon className={classes.icon} /> : <HiddenIcon className={classes.icon} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <InfoAlert style={{marginBottom: 10, display: isvalid === false ? '' : 'none'}} type='error' text={invalidtext} />
    </div>
  );
};

export default PetraInput;
