import { createSlice } from '@reduxjs/toolkit';
import { OFFLINE_STATUS } from '../../utils/constants';

const initialState = {
  list: [],
  loading: 'idle',
  newAccountPassword: '',
  newAccountPhrase: [],
  transactions: [
    {
      status: 'Success',
      timestamp: '12/12/19, 11:22 AM',
      address: '0x86aFE7Bef5E394E528c60570D5A480796DEC940F',
      amount: '12.33',
    },
    {
      status: 'Fail',
      timestamp: '08/03/20, 18:05 AM',
      address: '0xFe05749DEa5068f77dc52F20Eb70858A08FA53a1',
      amount: '0.00',
    },
    {
      status: 'Success',
      timestamp: '29/08/20, 12:30 AM',
      address: '0x6f06CC78a3a856185A81330F0576B0C6B23A928a',
      amount: '1.43',
    },
    {
      status: 'Success',
      timestamp: '15/09/20, 11:22 AM',
      address: '0xaDAEF63a6842a2A6541CAD6DDFc743f4c4c669BD',
      amount: '36.86',
    },
  ],
  assets: [
    {
      name: 'Asset Name',
      address: '0xF13E0AbCcffeB7579737f94A368b4A1781a1E996',
      available: 1412,
      deposited: 176,
      borrowed: 676.3,
    },
    {
      name: 'Asset Name 2',
      address: '0xF13E0AbCcffeB7579737f94A368b4A1781a1E996',
      available: 654,
      deposited: 145,
      borrowed: 100,
    },
    {
      name: 'Asset Name 3',
      address: '0xF13E0AbCcffeB7579737f94A368b4A1781a1E996',
      available: 36.96,
      deposited: 4932.96,
      borrowed: 38.9,
    },
  ],
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: initialState,
  reducers: {
    walletsLoading(state, action) {
      // Use a "state machine" approach for loading state instead of booleans
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    walletsReceived(state, action) {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.wallets = action.payload;
      }
    },
    resetNewAccount(state, action) {
      state.newAccountPassword = '';
    },
    updateNewAccountPassword(state, action) {
      state.newAccountPassword = action.payload;
    },
    updateNewAccountPhrase(state, action) {
      state.newAccountPhrase = action.payload;
    },
    addAccount: (state, action) => {
      state.list.push(action.payload);
    },
    updateAccountBalance(state, action) {
      const index = state.list.findIndex(
        (item) => item.address === action.payload.address
      );
      if (index >= 0 && action.payload.balance !== null) {
        state.list[index].pta = parseFloat(action.payload.balance) ?? 0;
      }
    },
    updateAccount: (state, action) => {
      const index = state.list.findIndex(
        (item) => item.address === action.payload.address
      );
      if (index >= 0) {
        let newOrder = parseInt(action.payload.order);
        let oldOrder = index + 1;
        if (oldOrder !== newOrder) {
          if (newOrder < oldOrder) {
            for (let i = newOrder - 1; i < oldOrder - 1; i++) {
              state.list[i].order = parseInt(state.list[i].order) + 1;
            }
          } else {
            for (let i = newOrder - 1; i > oldOrder - 1; i--) {
              state.list[i].order = parseInt(state.list[i].order) - 1;
            }
          }
        }
        state.list[index].name = action.payload.name;
        state.list[index].order = newOrder;
        state.list.sort((a, b) => (a.order > b.order ? 1 : -1));
      }
    },
    removeAccount: (state, action) => {
      const index = state.list.findIndex(
        (item) => item.address === action.payload
      );
      let hasMoreAccounts = true;
      let startIndex = index;
      if (index >= 0) {
        while (hasMoreAccounts) {
          if (state.list[startIndex]) {
            state.list[startIndex].order =
              parseInt(state.list[startIndex].order) - 1;
            startIndex += 1;
          } else {
            hasMoreAccounts = false;
          }
        }
        state.list = state.list.filter(
          (item) => item.address !== action.payload
        );
        state.list.sort((a, b) => (a.order > b.order ? 1 : -1));
      }
    },
    removeAllAccounts: (state, action) => {
      state.list = [];
    },
    updateAccountTransactions: (state, action) => {
      const index = state.list.findIndex(
        (item) => item.address === action.payload.address
      );
      if (index >= 0) {
        for (let i = 0; i < state.list[index].transactions?.length; i++) {
          let transaction = state.list[index].transactions[i];
          if (
            action.payload.replaceTransaction &&
            transaction.tempIndex ===
              action.payload.replaceTransaction.tempIndex
          ) {
            transaction = action.payload.replaceTransaction;
          }
          if (
            !action.payload.transactions.find(
              (x) => x.hash == transaction?.hash
            )
          ) {
            if (
              action.payload.blockNumber - transaction.blockNumber > 3 ||
              !transaction.blockNumber
            ) {
              if (transaction.txreceipt_status !== OFFLINE_STATUS) {
                transaction.txreceipt_status = '0';
              }
            }
            action.payload.transactions.unshift(transaction);
          } else if (!transaction?.hash) {
            action.payload.transactions.unshift(transaction);
          }
        }
        state.list[index].transactions = action.payload.transactions?.sort(
          (a, b) => b.timeStamp - a.timeStamp
        );
      }
    },
    updateTransaction: (state, action) => {
      const index = state.list.findIndex(
        (item) => item.address === action.payload.address
      );
      for (let i = 0; i < state.list[index].transactions?.length; i++) {
        let transaction = state.list[index].transactions[i];
        if (
          action.payload.replaceTransaction &&
          transaction.tempIndex === action.payload.replaceTransaction.tempIndex
        ) {
          state.list[index].transactions[i] = action.payload.replaceTransaction;
        }
      }
    },

    addPendingTransaction: (state, action) => {
      const index = state.list.findIndex(
        (item) => item.address === action.payload.address
      );
      if (index >= 0 && action.payload.transaction) {
        state.list[index].transactions.unshift(action.payload.transaction);
      }
    },
    cancelProcessingTransactions: (state, action) => {
      for (let i = 0; i < action.payload.accounts.length; i++) {
        const index = state.list.findIndex(
          (item) => item.address === action.payload.accounts[i].address
        );
        if (index >= 0) {
          for (var y = 0; y < state.list[index].transactions?.length; y++) {
            if (
              state.list[index].transactions[y].txreceipt_status ===
              OFFLINE_STATUS
            ) {
              state.list[index].transactions[y].txreceipt_status = '0';
            }
          }
        }
      }
    },
  },
});

export const {
  walletsLoading,
  walletsReceived,
  updateNewAccountPassword,
  updateNewAccountPhrase,
  updateAccountBalance,
  resetNewAccount,
  addAccount,
  updateAccount,
  removeAccount,
  updateTransaction,
  updateAccountTransactions,
  addPendingTransaction,
  removeAllAccounts,
  cancelProcessingTransactions,
} = accountsSlice.actions;

export { accountsSlice };
